import { defineStore, acceptHMRUpdate } from "pinia"
import { convertLocal2Utc, deepClone, round } from "../helpers"
import { PersistenceService } from "../services/persistenceService"

import type { IDataSource, IDataFieldConfig, IDataPoint } from "../services/dataProvider"
import { SyncSessionService } from "../services/syncSessionService"

const storeCache: Map<string, ReturnType<typeof createDataStreamStore>> = new Map()

let storeInit: IDataSource = {
  id: "",
  name: "",
  dataSourceConfigId: "",
  data: [] as IDataPoint[],
  schema: [] as IDataFieldConfig[],
  lastUpdateUtc: 0
}

const fakeUpdate = (dataSource: IDataSource) => {
  const now = new Date()
  let utcNow = convertLocal2Utc(+now)
  dataSource.lastUpdateUtc = utcNow

  dataSource.data.push({
    timeUtc: utcNow,
    values: {
      y: round((+Math.random() * 10000) / (Math.random() < 0.5 ? 10 : 1), 2),
      y1: round((+Math.random() * 10000) / (Math.random() < 0.5 ? 10 : 1), 2)
    }
  })
}

function createDataStreamStore(dataSourceId: string) {
  const persistenceService = new PersistenceService()
  const syncSessionService = new SyncSessionService()

  const useDataStore = defineStore({
    id: `datastream_${dataSourceId}`,
    state: () => Object.assign(deepClone(storeInit), { id: dataSourceId }),
    lifecycle: {
      onLoad: (store) => {
        if (syncSessionService.isSessionClient) {
          syncSessionService.registerStore(store)
          return syncSessionService.loadFromSyncStore(store.$id)
        }
        syncSessionService.registerStore(store)
        return persistenceService.loadDataStream(store.$id.substring("datastream_".length))
      }
    },
    actions: {
      addData(data: Record<string, string | number>) {
        this.data.push({
          timeUtc: +new Date(),
          values: data
        })
        this.lastUpdate = new Date().toISOString()
        // todo
        // this.persist()
      }
    }
  })

  if (import.meta.hot) {
    // see https://pinia.esm.dev/cookbook/hot-module-replacement.html
    import.meta.hot.accept(acceptHMRUpdate(useDataStore, import.meta.hot))
  }

  let store = useDataStore()

  if (!syncSessionService.isSessionClient && dataSourceId.endsWith("autoupdate")) {
    setTimeout(() => {
      fakeUpdate(store.$state)
    }, 200)
    setInterval(() => {
      fakeUpdate(store.$state)
    }, 4000)
  }

  return store
}

export function getDataSourceStore(id: string) {
  const cacheKey = `${id}`
  if (!storeCache.has(cacheKey)) {
    storeCache.set(cacheKey, createDataStreamStore(id))
  }

  return storeCache.get(cacheKey)!
}

SyncSessionService.registerStoreCreation("datastream_", (storeId) => getDataSourceStore(storeId))
