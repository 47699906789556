import { InjectionKey } from "@vue/runtime-core"

export type DragMode = "moveslice" | "movetile" | "resize" | "addtile" | null
export type RevealMode = "datasource" | null
export type ModalType = "contextmenu" | "map-datasource" | "data-view" | "info" | null

export interface DashboardUiState {
  inEditMode: boolean
  isScrolling: boolean
  selectedTileId: string | null
  draggedTileId: string | null
  dragMode: DragMode
  revealMode: RevealMode
  modalMode: ModalType
  focusedDataTileId: string | null
  focusedDataTimeUtc: number
  focusedDataFollowActive: boolean
  focusedDataEventSource: "chart" | "dataview"
}

export interface PreviewTilePosition {
  sliceId: string
  x: number
  y: number
  width: number
  height: number
}

export interface TileViewState {
  isResizing: boolean
  suppressAnimations: boolean
  isTouched: boolean
  isHovered: boolean
}

export interface ChartWidgetViewState {
  hovered: boolean
  isVerticalCompact: boolean
  width: number
  height: number
  margin: number
  dimX: number
  dimY: number
  maxY: number
  chartWidth: number
  chartHeight: number
  xAxisHeight: number
  /** overall point index over all charts that is focused */
  focusedPointIndex: number
  /** utc time of focused point */
  focusedUtcTime: number
  // todo: move this one out to individual "noise/view config" thingie
  showMarkersAlways: boolean
  glowIntensity: number
}

export const DashboardUiStateKey = Symbol() as InjectionKey<Readonly<DashboardUiState>>
export const TileViewStateKey = Symbol() as InjectionKey<Readonly<TileViewState>>
export const ChartWidgetViewStateKey = Symbol() as InjectionKey<Readonly<ChartWidgetViewState>>
