import { ref, Ref, watch, InjectionKey, WatchStopHandle } from "@vue/runtime-core"

export interface IDataSourceConfig {
  /** id of datasource config; can be used by multiple data sources */
  id: string
  /** id from original config this was created from */
  sourceConfigId: string
  /** base fetch URL */
  url: string
  /** the symbol to show in e.g. the corner of tiles */
  symbol: string
  // auth config reference
  // fetch location
  fetchLocation: string
  fetchLocationDescription: string

  // DO/store location
  storeLocation: string
  storeLocationDescription: string
}

export interface ITimed {
  readonly timeUtc: number
}

/** represents a whole DataPoint in time aggregated through API query */
export interface IDataPoint {
  readonly timeUtc: number
  readonly values: Record<string, string | number>
}

export interface IDataPointValue<T> {
  readonly timeUtc: number
  readonly value: T
}

export interface IDataSource {
  /** id of data source */
  id: string
  /** user-given name of data source */
  name: string
  /** id of config this datasource is derived from */
  dataSourceConfigId: string
  /** array of data, should not be mutated */
  data: IDataPoint[]
  /** schema of data fields */
  schema: IDataFieldConfig[]
  /** reactive lastUpdate to trigger downstream listeners */
  lastUpdateUtc: number
}

export interface IDataFieldConfig {
  /** id of data field */
  id: string
  /** field/column name, maybe path of field in e.g. JSON structure, but path prob. not necessary */
  fieldName: string
  /** type of datafield, used for parsing */
  type: "string" | "number"
}

export type TimeUnit = "second" | "minute" | "hour" | "day" | "week" | "month" | "year"

export interface ITimeRange {
  /** relative2end: relative timerange dependent end time, given by units and unitType
      relative2end-rounded: like relative2end, but start time is rounded to last full unit
        e.g. unitType 'day' units: 1, endType: 'now' means last day but starting from midnight of current day
      fixed: startTime is used
   */
  startType: "relative2end" | "relative2end-rounded" | "fixed"
  /** now: end time is now
      now-rounded: like now, but end time is rounded to last full unit
        e.g. unitType 'hour' units: 3, "now" is 12:20, means end time is 12:00, as last unit (hour) gets rounded down
      fixed: endTime is used
   */
  endType: "now" | "now-rounded" | "fixed"
  /** only relevant when startType is 'fixed' */
  startTime?: Date
  /** only relevant when endType is 'fixed' */
  endTime?: Date
  /** unit we use for relative start time computation and rounding */
  unitType: TimeUnit
  /** amount of unitType units to use for relative time computation */
  unitAmount?: number
}

// see https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
export type ToArray<Type> = Type extends any ? Type[] : never

export interface IDataProvider {
  lastUpdate: Ref<number | null>
  dataSourceId: Ref<string | null>
  dataSource: IDataSource | null
  /** returns dataset value typeof */
  getDataSetValueType(fieldName: string): "string" | "number"
  /** get array of times for a given time range */

  getFirstUtcTime(): number
  getUtcTimeRange(timeRangeUtc: [number, number]): number[]
  /** allows for e.g. child chart components to get different x/y datasets for rendering */
  getTimeDataRange(timeRangeUtc: [number, number], fieldName: string): IDataPointValue<number>[]
  /** allows for e.g. child chart components to get different x/y datasets for rendering */
  getLastData(last: number, fieldName: string): IDataPointValue<number>[]
  /** allows for e.g. child chart components to get different x/y datasets for rendering */
  getLastDataPoints(last: number): IDataPoint[]
  getDataByTime(timeRangeUtc: [number, number]): Promise<IDataPoint[]>
  /** allows for e.g. child chart components to get different x/y datasets for rendering */
  getDataPoint(time: number, fieldName: string): IDataPointValue<number>
  /** fetches last data point */
  getLastDataPoint(fieldName: string): IDataPointValue<number>
  /** allows for datapoint retrival by data index */
  getDataPointFromIndex(index: number, fieldName: string): IDataPointValue<any>
  getTimeFromIndex(index: number): Date
  // getIndexFromTime(index: number): Date
}

/**
  Takes a raw datasource reactive (a store's state) and provides methods for widgets
  to retrieve data in specific timeranges from the **transformed** fields
  Should prob. be finally implemented as composable function and not as class
 */
export class DataProvider implements IDataProvider {
  dataSource: IDataSource | null
  lastUpdate: Ref<number | null>
  dataSourceId: Ref<string | null>
  watchStopHandle: WatchStopHandle

  constructor() {
    this.dataSource = null
    this.lastUpdate = ref(null)
    this.watchStopHandle = () => {}
    this.dataSourceId = ref(null)
  }

  loadDataStore(dataSource: IDataSource) {
    this.dataSource = dataSource
    this.dataSourceId.value = dataSource.id
    this.lastUpdate.value = dataSource.lastUpdateUtc

    this.watchStopHandle()

    // is there a smarter way to pass the ref directly?
    this.watchStopHandle = watch(
      () => dataSource.lastUpdateUtc,
      (newVal) => {
        this.lastUpdate.value = newVal
      },
      { immediate: true }
    )
  }

  getDataSetValueType(fieldName: string): "string" | "number" {
    return "string"
  }

  getFirstUtcTime() {
    return this.dataSource!.data[0].timeUtc
  }

  getDataPointFromIndex(index: number, fieldName: string): IDataPointValue<any> {
    if (!fieldName) throw new Error(`filedName missing`)
    if (!this.dataSource) throw new Error(`datasource not loaded`)

    if (index >= this.dataSource.data.length) throw new Error(`index bigger than data length`)

    var data = this.dataSource.data[index]

    return {
      timeUtc: data.timeUtc,
      value: data.values[fieldName]
    } as IDataPointValue<any>
  }

  getTimeFromIndex(index: number): Date {
    if (!this.dataSource) throw new Error(`datasource not loaded`)

    if (index >= this.dataSource.data.length) throw new Error(`index bigger than data length`)

    var data = this.dataSource.data[index]

    return new Date(data.timeUtc)
  }

  getDataPoint(time: number, fieldName: string): IDataPointValue<any> {
    if (!fieldName) throw new Error(`filedName missing`)
    if (!this.dataSource) throw new Error(`datasource not loaded`)

    var data = this.dataSource.data.filter((d) => d.timeUtc == +time)[0]
    if (!data) {
      throw new Error(`no data for time ${time} found`)
    }

    return {
      timeUtc: data.timeUtc,
      value: data.values[fieldName]
    } as IDataPointValue<any>
  }

  getLastDataPoint(fieldName: string): IDataPointValue<any> {
    if (!fieldName) throw new Error(`filedName missing`)
    if (!this.dataSource) throw new Error(`datasource not loaded`)

    var data = this.dataSource.data[this.dataSource.data.length - 1]

    return {
      timeUtc: data.timeUtc,
      value: data.values[fieldName]
    } as IDataPointValue<any>
  }

  getLastData(last: number, fieldName: string): IDataPointValue<any>[] {
    if (!fieldName) throw new Error(`filedName missing`)
    if (!this.dataSource) throw new Error(`datasource not loaded`)
    // filter values by timerange
    // extract fieldName and apply

    return (
      this.dataSource.data
        .slice(-last)
        // todo: do not directly map, but use dynamic mapping config to
        // retrieve original field + apply data transforms
        .map((d) => {
          return {
            timeUtc: d.timeUtc,
            value: d.values[fieldName]
          } as IDataPointValue<any>
        })
    )
  }

  getLastDataPoints(last: number): IDataPoint[] {
    return this.dataSource!.data.slice(-last)
  }

  getUtcTimeRange(timeRangeUtc: [number, number]): number[] {
    if (!this.dataSource) throw new Error(`datasource not loaded`)
    // filter values by timerange
    // extract fieldName and apply

    const [timeStart, timeEnd] = timeRangeUtc
    // console.log([timeStart, timeEnd])

    return (
      this.dataSource.data
        .filter((d) => d.timeUtc >= timeStart && d.timeUtc <= timeEnd)
        // todo: do not directly map, but use dynamic mapping config to
        // retrieve original field + apply data transforms
        .map((d) => d.timeUtc)
    )
  }

  async getDataByTime(timeRangeUtc: [number, number]): Promise<IDataPoint[]> {
    const [timeStart, timeEnd] = timeRangeUtc
    // console.log([timeStart, timeEnd])
    if (timeEnd < this.dataSource!.data[0].timeUtc || timeStart > this.dataSource!.data.at(-1)!.timeUtc) {
      return []
    }

    let startIndex = Math.max(
      0,
      this.dataSource!.data.findIndex((d) => d.timeUtc >= timeStart)
    )
    const bufferedMinTime = this.dataSource!.data[startIndex].timeUtc

    return this.dataSource!.data.filter((d) => d.timeUtc >= bufferedMinTime && d.timeUtc <= timeEnd)
  }

  getTimeDataRange(timeRangeUtc: [number, number], fieldName: string): IDataPointValue<any>[] {
    if (!fieldName) throw new Error(`filedName missing`)
    if (!this.dataSource) throw new Error(`datasource not loaded`)
    // filter values by timerange
    // extract fieldName and apply

    const [timeStart, timeEnd] = timeRangeUtc

    return (
      this.dataSource.data
        .filter((d) => d.timeUtc >= timeStart && d.timeUtc <= timeEnd)
        // todo: do not directly map, but use dynamic mapping config to
        // retrieve original field + apply data transforms
        .map((d) => {
          return {
            timeUtc: d.timeUtc,
            value: d.values[fieldName]
          } as IDataPointValue<any>
        })
    )
  }
}

export const DataProviderKey = Symbol() as InjectionKey<Readonly<IDataProvider>>
