import { unref, watchEffect, Ref, ref } from "vue"

export type ElementPosition = { top: number; left: number }

/** computes element positioning within optional parent (if undefined take window) that avoids target rectangle */
export function useElementPositioning(
  elemtToPosition: Ref<HTMLDivElement>,
  elementToAvoid: Ref<Element | null>,
  options: {
    boundingElement?: Ref<Element | null>
    trigger?: () => object
    elementToAvoidXOverride?: Ref<number | undefined>
    elementToAvoidYOverride?: Ref<number | undefined>
  } = {}
): {
  position: Ref<ElementPosition>
  elementVisible: Ref<boolean>
} {
  // setup reactive re-fetch if input URL is a ref
  let position = ref({ top: 0, left: 0 })
  let elementVisible = ref(false)

  // console.warn("init", elementToAvoid)

  function computePosition(): ElementPosition {
    const elemToPosition = unref(elemtToPosition)
    const elemToAvoid = unref(elementToAvoid)
    const elemAsBoundary = unref(options.boundingElement!)
    const elemToAvoidXOverride = unref(options.elementToAvoidXOverride)
    const elemToAvoidYOverride = unref(options.elementToAvoidYOverride)

    options.trigger?.()

    if (
      !elementVisible.value ||
      !elemToPosition ||
      !(elemToAvoid || (elemToAvoidXOverride && elemToAvoidYOverride)) ||
      !elemAsBoundary
    ) {
      return {
        top: 0,
        left: -200
      }
    }

    let avoidRect: Omit<DOMRectReadOnly, "toJSON"> | undefined

    const contentRect = elemToPosition.getBoundingClientRect()
    avoidRect = elemToAvoid?.getBoundingClientRect()
    const boundingRect = elemAsBoundary.getBoundingClientRect()

    const padding = 5

    if (elemToAvoidXOverride && elemToAvoidYOverride) {
      const x = elemToAvoidXOverride + padding
      const y = elemToAvoidYOverride - 10
      avoidRect = {
        x: x,
        left: x,
        right: x,
        y: y,
        top: y,
        bottom: y,
        height: 1,
        width: 1
      }
    } else if (!elemToAvoid) {
      return {
        top: 0,
        left: -200
      }
    }
    if (!avoidRect) {
      return {
        top: 0,
        left: -200
      }
    }

    // logic: try right upper, else left upper, else above but rightmost, then under but rightmost
    // if all fails take least obtrusive

    let leftPos: number | null = null
    let topPos: number | null = null

    // try right upper
    if (boundingRect.right >= avoidRect.right + contentRect.width + padding) {
      leftPos = avoidRect.right + padding
    } else if (boundingRect.left <= avoidRect.left - contentRect.width - padding) {
      leftPos = avoidRect.left - contentRect.width - padding
    } else {
      leftPos =
        boundingRect.right - avoidRect.right + 10 > avoidRect.left - boundingRect.left
          ? boundingRect.right - contentRect.width
          : boundingRect.left
    }

    topPos = Math.min(avoidRect.top, boundingRect.bottom - contentRect.height - padding)
    return {
      top: Math.round(topPos),
      left: Math.round(leftPos)
    }
  }

  watchEffect(
    async () => {
      const pos = computePosition()
      position.value.left = pos.left
      position.value.top = pos.top
      // console.warn(unref(position))
    },
    {
      flush: "post"
    }
  )

  return {
    position,
    elementVisible
  }
}
