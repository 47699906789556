import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import { createPinia } from "pinia"
import { MotionOnePlugin } from "vue-motion-one"
import { PiniaLifecycle } from "./plugins/pinia-lifecycle"
import { PiniaUndo } from "./plugins/pinia-undo"
import { registerResizeDirective } from "./directives"
import { fetchUserInfo, getUserInfo } from "./services/authService"

import "./index.css"
// @ts-ignore
import routes from "~pages"
console.debug("used routes", routes)

//@ts-ignore
import App from "./App.vue"

const app = createApp(App)

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

// fetch user info into store before routing becomes activated
await fetchUserInfo()

router.beforeEach((to) => {
  console.debug("routing to", to)
  if (to.path.startsWith("/api")) return

  if (to.matched.length == 0) return "/404"

  // see https://router.vuejs.org/guide/advanced/meta.html#route-meta-fields
  if (to.meta.requiresAuth && !getUserInfo()) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: "/login",
      // save the location we were at to come back later
      query: { redirect: to.fullPath }
    }
  }
})

app.use(router)

const pinia = createPinia()
pinia.use(PiniaLifecycle)
pinia.use(PiniaUndo)

app.use(pinia).use(MotionOnePlugin)

registerResizeDirective(app)

/**
 * handle global error
 * @param err
 * @param vm
 * @param info Vue-specific error info, e.g. which lifecycle hook the error was found in
 */
app.config.errorHandler = (err, vm, info) => {
  console.error("global error captured", err, vm, info)
}

app.config.performance = true

app.mount("#app")
