import { InjectionKey } from "@vue/runtime-core"
import { defineStore, acceptHMRUpdate } from "pinia"
import { deepClone } from "../helpers"
import { TileConfig } from "../models/tile-models"
import { PersistenceService } from "../services/persistenceService"
import { SyncSessionService } from "../services/syncSessionService"

const storeCache: Map<string, ReturnType<typeof createTileConfigStore>> = new Map()

let storeInit: TileConfig = {
  id: "",
  dataSourceId: "",
  title: "",
  symbolId: undefined,
  widgetType: "none",
  dataViewConfig: {
    viewType: "flex",
    itemConfig: {
      fields: []
    },
    timeVisibility: "default"
  },
  numberWidgetConfig: {
    fieldName: "",
    animate: true,
    format: {},
    colorRanges: []
  },
  gaugeWidgetConfig: {
    fieldName: "",
    colorOverride: "#00a303",
    colorRanges: [],
    numberFormat: {},
    animateNumbers: true,
    layout: "auto",
    max: 10000
  },
  chartWidgetConfig: {
    showVoronoi: false,
    randomizeData: false,
    glowIntensity: 0,
    showMarkersAlways: false,
    useHoverDepthPerChart: false,
    chartConfigs: []
  }
}

const storePrefix = "tileconfig_"

function createTileConfigStore(widgetId: string) {
  const persistenceService = new PersistenceService()
  const syncSessionService = new SyncSessionService()

  // console.log("defining store", widgetId)
  const useTileConfigStore = defineStore({
    id: `${storePrefix}${widgetId}`,
    state: () => Object.assign(deepClone(storeInit), { id: widgetId }),
    lifecycle: {
      onLoad: (store) => {
        if (syncSessionService.isSessionClient) {
          syncSessionService.registerStore(store)
          return syncSessionService.loadFromSyncStore(store.$id)
        }
        syncSessionService.registerStore(store)
        return persistenceService.loadTileConfig(store.$id.substring(storePrefix.length))
      },
      // afterLoad: (store) => syncSessionService.registerStore(store),
      onPersist: (_, state) => {
        if (!syncSessionService.isSessionClient) persistenceService.saveTileConfig(state)
      },
      persistOnUpdate: true
    },
    getters: {
      // chartWidth: state => state.width - 2 * state.padding,
      // chartHeight: state => state.height - 2 * state.padding,
    }
  })
  if (import.meta.hot) {
    // see https://pinia.esm.dev/cookbook/hot-module-replacement.html
    import.meta.hot.accept(acceptHMRUpdate(useTileConfigStore, import.meta.hot))
  }

  return useTileConfigStore()
}

export function getTileConfigStore(id: string) {
  if (!storeCache.has(id)) {
    storeCache.set(id, createTileConfigStore(id))
  }

  return storeCache.get(id)
}

SyncSessionService.registerStoreCreation(storePrefix, (storeId) => getTileConfigStore(storeId)!)

export const TileConfigStoreKey = Symbol() as InjectionKey<ReturnType<typeof getTileConfigStore>>
export const TileConfigStateKey = Symbol() as InjectionKey<Readonly<TileConfig>>
