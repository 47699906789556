import { InjectionKey } from "@vue/runtime-core"
import { defineStore, acceptHMRUpdate } from "pinia"
import { deepClone } from "../helpers"
import { PersistenceService } from "../services/persistenceService"
const persistenceService = new PersistenceService()

export interface UserConfig {
  /** unique user id */
  id: string
  /** the user's name */
  name: string
  /** whether user has uploaded picture */
  hasPicture: boolean
  /** ids of all joined orgs */
  orgs: string[]

  userSettings: UserSettings

  userAgentPrefs: UserAgentSettings
}

export interface UserSettings {
  /** whether user has uploaded picture */
  defaultOrg?: string

  /** if set overrides use of useragent locale */
  preferredLocale?: string
}

export interface UserAgentSettings {
  theme?: "dark" | "light" | "system"
}

let storeInit: UserConfig = {
  id: "",
  hasPicture: false,
  name: "",
  userAgentPrefs: {
    theme: "system"
  },
  userSettings: {
    preferredLocale: undefined,
    defaultOrg: undefined
  },
  orgs: [] as string[]
}

const useStore = defineStore({
  id: `user`,
  state: () => deepClone(storeInit),
  actions: {
    setUserLanguage(locale: string) {
      this.userSettings.preferredLocale = locale
    }
  },
  lifecycle: {
    onLoad: () => persistenceService.loadUser(),
    onPersist: (store, state) => persistenceService.saveUser(state),
    persistOnUpdate: true
  }
})
if (import.meta.hot) {
  // see https://pinia.esm.dev/cookbook/hot-module-replacement.html
  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
}

// ensure singleton semantics of this store
export function getUserDataStore() {
  return useStore()
}

export const UserConfigStoreKey = Symbol() as InjectionKey<ReturnType<typeof getUserDataStore>>
export const UserConfigStateKey = Symbol() as InjectionKey<Readonly<UserConfig>>
