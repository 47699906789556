import { defineStore, acceptHMRUpdate } from "pinia"
import { deepClone } from "../helpers"
import { IDataSourceConfig } from "../services/dataProvider"
import { PersistenceService } from "../services/persistenceService"

const storeCache: Map<string, ReturnType<typeof createDataSourceConfigStore>> = new Map()

let storeInit: Partial<IDataSourceConfig> = {
  id: ""
}

function createDataSourceConfigStore(datasourceConfigId: string) {
  const persistenceService = new PersistenceService()

  const useStore = defineStore({
    id: `datasource_${datasourceConfigId}`,
    state: () => Object.assign(deepClone(storeInit), { id: datasourceConfigId }),
    lifecycle: {
      onLoad: (store) => persistenceService.loadDataSourceConfig(store.$id.substr("datasource_".length)),
      onUpdate: (store, state) => {
        console.log("onUpdate", store.$id, JSON.stringify(state))
      }
    }
  })
  if (import.meta.hot) {
    // see https://pinia.esm.dev/cookbook/hot-module-replacement.html
    import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
  }

  return useStore()
}

export function getDataSourceConfigStore(id: string) {
  if (!storeCache.has(id)) {
    storeCache.set(id, createDataSourceConfigStore(id))
  }

  return storeCache.get(id)!
}
