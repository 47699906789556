import { ref, watchEffect, Ref } from "vue"

type ScaleType = "linear" | "exponential"

export interface TimeScaleOptions {
  minTimeUtc: Ref<number>
  /** max == null means "now" */
  maxTimeUtc: Ref<number>
  type: Ref<ScaleType>
  range: Ref<number>
}

/**
 * Maps a time domain onto a number range (typically time raneg to chart width)
 * @param options the time scale options
 * @returns scaled coordinates
 */
export function useTimeScale(
  utcTimes: Ref<number[]>,
  timeTranslate: Ref<number>,
  translateFactor: Ref<number>,
  options: TimeScaleOptions
) {
  let scaledValues: Ref<number[]> = ref([])

  watchEffect(() => {
    const minTime = Math.min(options.minTimeUtc.value, options.minTimeUtc.value + timeTranslate.value)
    const maxTime = Math.max(options.maxTimeUtc.value, options.maxTimeUtc.value + timeTranslate.value)
    // const minTime = options.minTimeUtc.value
    // const maxTime = options.maxTimeUtc.value

    // console.warn("min, max", new Date(minTime), new Date(maxTime))
    // console.warn("min, max, domain, range", minTime, maxTime, options.range.value)

    scaledValues.value = scaleTimes(utcTimes.value, options.type.value, minTime, maxTime, options.range.value)
    // console.warn("recompute x scale", scaledValues.value.length)
  })

  function scaleTime(utcTime: number, useFactor: boolean): number {
    // scale based on all min/max when using autoscale
    if (options.type.value != "linear") {
      throw new Error(`scale type ${options.type.value} not supported`)
    }

    const domainSize = options.maxTimeUtc.value - options.minTimeUtc.value

    const translate = timeTranslate.value * (useFactor ? 1 - translateFactor.value : 1)
    // const minTime = Math.min(options.minTimeUtc.value, options.minTimeUtc.value + timeTranslate.value)

    const scaled = ((utcTime - (options.minTimeUtc.value + translate)) / domainSize) * options.range.value

    return scaled
  }

  function scaleTimes(
    utcTimes: number[],
    type: ScaleType,
    domainMin: number,
    domainMax: number,
    range: number
  ): number[] {
    // scale based on all min/max when using autoscale
    if (type != "linear") {
      throw new Error(`scale type ${type} not supported`)
    }

    const domainSize = domainMax - domainMin
    // const translate = timeTranslate.value * (1 - translateFactor.value)
    const scaled = utcTimes.map((v) => (((v - domainMin) / domainSize) * range) | 0)

    return scaled
  }

  return {
    scaledValues,
    scaleTime
  }
}
