<script lang="ts">
import { ResizeType } from "./dashboard-layout"

export type ResizeTileEvent = CustomEvent<{ tileId: string; resizeType: ResizeType; mouseX: number; mouseY: number }>

function createResizeTileEvent(tileId: string, resizeType: ResizeType, e: PointerEvent): ResizeTileEvent {
  return new CustomEvent("resize-tile", {
    detail: {
      tileId: tileId,
      resizeType: resizeType,
      mouseX: e.clientX,
      mouseY: e.clientY
    },
    bubbles: true
  })
}

export type MoveTileEvent = CustomEvent<{ tileId: string }>

function createMoveTileEvent(tileId: string): MoveTileEvent {
  return new CustomEvent("move-tile", {
    detail: {
      tileId: tileId
    },
    bubbles: true
  })
}
</script>

<script setup lang="ts">
import { inject } from "vue"
import { DashboardUiStateKey } from "./dashboard-ui-models"

const props = defineProps<{
  tileId: string
  tileHovered: boolean
  tileSelected: boolean
  width: number
  height: number
}>()

const dbUiState = inject(DashboardUiStateKey)!

let dragRef = $ref<HTMLDivElement>()

// if we use a touch device
const mql = window.matchMedia("(hover: none)")
// wouldn't need to be ref, but easier for development, and maybe prevents future edge cases
let isTouch = $ref(mql.matches)
mql.onchange = (ev) => (isTouch = ev.matches)

const isDragDisabled = $computed(
  () => !dbUiState.inEditMode || dbUiState.modalMode == "map-datasource" || dbUiState.revealMode || dbUiState.dragMode
)

const startMove = (e: PointerEvent) => {
  e.preventDefault()
  dragRef.dispatchEvent(createMoveTileEvent(props.tileId))
}
const startResize = (corner: ResizeType, e: PointerEvent) => {
  e.preventDefault()
  dragRef.dispatchEvent(createResizeTileEvent(props.tileId, corner, e))
}

//#endregion
</script>

<template>
  <div
    ref="dragRef"
    class="drag-layer z-50 h-full w-full text-white"
    :class="{
      edit: dbUiState.inEditMode,
      hovered: tileHovered,
      selected: tileSelected,
      'drag-disabled': isDragDisabled
    }"
  >
    <div
      v-if="width > 1"
      class="btn-primary group absolute top-0 grid h-5 w-[40%] cursor-ns-resize place-items-center rounded opacity-75"
      @pointerdown="startResize('nn', $event)"
    >
      <div
        class="drag-span pointer-events-none h-1 w-[60%] rounded transition-all group-hover:h-1.5 group-hover:w-[100%]"
        :class="{ '!h-2': isTouch }"
      ></div>
    </div>
    <div
      v-if="height > 1"
      class="group absolute right-0 grid h-[40%] w-5 cursor-ew-resize place-items-center rounded opacity-75"
      @pointerdown="startResize('ee', $event)"
    >
      <div
        class="drag-span pointer-events-none h-[60%] w-1 rounded transition-all group-hover:h-[100%] group-hover:w-1.5"
        :class="{ '!w-2': isTouch }"
      ></div>
    </div>
    <div
      v-if="width > 1"
      class="group absolute bottom-0 grid h-5 w-[40%] cursor-ns-resize place-items-center rounded opacity-75"
      @pointerdown="startResize('ss', $event)"
    >
      <div
        class="drag-span pointer-events-none h-1 w-[60%] rounded transition-all group-hover:h-1.5 group-hover:w-[100%]"
        :class="{ '!h-2': isTouch }"
      ></div>
    </div>
    <div
      v-if="height > 1"
      class="group absolute left-0 grid h-[40%] w-5 cursor-ew-resize place-items-center rounded opacity-75"
      @pointerdown="startResize('ww', $event)"
    >
      <div
        class="drag-span pointer-events-none h-[60%] w-1 rounded transition-all group-hover:h-[100%] group-hover:w-1.5"
        :class="{ '!w-2': isTouch }"
      ></div>
    </div>

    <div class="group absolute top-0 left-0 h-12 w-12 cursor-nwse-resize" @pointerdown="startResize('nw', $event)">
      <div
        class="drag-span absolute pointer-events-none top-0 left-0 -m-7 rotate-45 h-12 w-12 opacity-75 transition-transform group-hover:translate-y-1 group-hover:translate-x-1"
      ></div>
    </div>

    <div class="group absolute top-0 right-0 h-12 w-12 cursor-nesw-resize" @pointerdown="startResize('ne', $event)">
      <div
        class="drag-span absolute pointer-events-none top-0 right-0 -m-7 rotate-45 h-12 w-12 opacity-75 transition-transform group-hover:translate-y-1 group-hover:-translate-x-1"
      ></div>
    </div>

    <div class="group absolute bottom-0 right-0 h-12 w-12 cursor-nwse-resize" @pointerdown="startResize('se', $event)">
      <div
        class="drag-span pointer-events-none absolute bottom-0 right-0 -m-7 rotate-45 h-12 w-12 opacity-75 transition-transform group-hover:-translate-y-1 group-hover:-translate-x-1"
      ></div>
    </div>

    <div class="group absolute bottom-0 left-0 h-12 w-12 cursor-nesw-resize" @pointerdown="startResize('sw', $event)">
      <div
        class="drag-span pointer-events-none absolute bottom-0 left-0 -m-7 rotate-45 h-12 w-12 opacity-75 transition-transform group-hover:-translate-y-1 group-hover:translate-x-1"
      ></div>
    </div>

    <span class="move-zone drag-span cursor-move rounded opacity-50 hover:scale-110" @pointerdown="startMove">
      <i-mdi-cursor-move class="scale-110 select-none"></i-mdi-cursor-move>
    </span>
  </div>
</template>

<style scoped>
.drag-layer {
  pointer-events: none;
  user-select: none;
  /* position: relative; */

  grid-column: 1 / 4;
  grid-row: 1 / 4;
  display: flex;
  justify-content: center;
  align-items: center;

  will-change: transform opacity;
  transition: all 0.3s;
  transition-delay: 0.2s;
  /* transition-delay: 0.5s; */
  opacity: 0;
  transform: scale(1.1);
}
.drag-span {
  background: rgb(10, 108, 255);
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 3px 6px;
}

/* @media (hover: none) { */
.drag-layer:not(.drag-disabled).edit.selected {
  pointer-events: all;
  transform: scale(1);
  opacity: 1;
}
/* } */

@media (hover: hover) {
  .drag-layer:not(.drag-disabled).edit.hovered {
    pointer-events: all;
    transform: scale(1);
    opacity: 1;
  }
}

.drag-layer.edit > * {
  transition-property: transform, opacity;
  transition-duration: 0.2s;
}
.drag-layer.edit > *:hover {
  opacity: 1 !important;
}

.move-zone {
  height: min(50%, 50px);
  width: min(50%, 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-upper {
  top: 0;
  left: 0;
}

.right-upper {
  top: 0;
  right: 0;
}

.right-lower {
  bottom: 0;
  right: 0;
}

.left-lower {
  bottom: 0;
  left: 0;
}
</style>
