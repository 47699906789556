import { InjectionKey } from "vue"
import { defineStore, acceptHMRUpdate } from "pinia"
import { WebSocketStatus } from "@vueuse/core"

import { deepClone } from "../helpers"
import { IUaParserResult } from "../models/uaparser-models"
import { PersistenceService } from "../services/persistenceService"

export interface SessionInfo {
  userAgent: IUaParserResult
  clientId: string
}

export interface ServerEvent {
  timestamp: number
  message: string
  syncIndex: number | null
  /** denotes type, init has userAgent in message */
  type: "initclient" | "clientsupdate" | "syncupdate" | "sessionclosed"
  error?: string
  /** errorCode
   * 1: no valid sessionId
   * 2: sessionId not known on rejoin
   * 3: owner quit, session is closed
   * 99: unknown */
  errorCode?: 1 | 2 | 3 | 99
}

/**
 * A sync session, used to sync a dashboard with foreign devices, could be yours or another user's
 */
export interface SyncSessionState {
  sessionStarted: boolean
  /** sessionId to be shared with others;
   * sessions are ephemeral (an in-memory Durable Object getting cleaned up automatically)
   * and sessionId should be cleaned */
  sessionId: string | null
  /** whether we are session owner, meaning sharing state or retrieving shared state */
  isSessionHost: boolean
  /** clientId for the current connection */
  clientId: string | null

  lastEvent: ServerEvent | null

  wsStatus: WebSocketStatus
  /** the index that gets incremented on every update by the host */
  updateIndex: number
  lastUpdateTimeUtc: Date | null
  /** alls clientIds known */
  connectedClients: SessionInfo[]
}

let storeInit: SyncSessionState = {
  sessionStarted: false,
  sessionId: null,
  isSessionHost: false,
  clientId: null,
  lastEvent: null,
  wsStatus: "CLOSED",
  updateIndex: 0,
  lastUpdateTimeUtc: null,
  connectedClients: []
}

// ensure singleton semantics of this store
export function getSyncSessionStore() {
  const persistenceService = new PersistenceService()

  const useSessionStore = defineStore({
    id: `syncSession`,
    state: () => deepClone(storeInit),
    getters: {
      syncUrl(state): string {
        return `${location.origin}?syncSession=${state.sessionId}`
      },
      clientCount: (state) => state.connectedClients.length,
      clientStatus: (state) => {
        if (!state.sessionStarted) return "Sync off"
        if (state.wsStatus == "CONNECTING") return "Connecting.."
        if (state.wsStatus == "CLOSED") return "Problem"

        if (!state.isSessionHost) return "Syncing"

        return `${state.connectedClients.length} Synced`
      },
      isSessionConnectionDown() {
        // @ts-ignore
        return this.clientStatus == "Problem"
      },
      isSessionActive: (state) => !!state.sessionId,
      isSessionClient: (state) => !!state.sessionId && !state.isSessionHost
    },
    lifecycle: {
      onLoad: async (store) => {
        // we always first check for URL param, if not present we load from storage
        const searchParams = new URLSearchParams(location.search)
        const sessionIdFromUrl = searchParams.get("syncSession")
        if (sessionIdFromUrl) {
          store.$state.sessionId = sessionIdFromUrl
        } else {
          return persistenceService.loadSyncSession()
        }
      },
      onPersist: (_, state) => {
        if (state.sessionId) persistenceService.persistSyncSession(state)
      }
    }
  })
  if (import.meta.hot) {
    // see https://pinia.esm.dev/cookbook/hot-module-replacement.html
    import.meta.hot.accept(acceptHMRUpdate(useSessionStore, import.meta.hot))
  }

  return useSessionStore()
}

export const SyncSessionStoreKey = Symbol() as InjectionKey<ReturnType<typeof getSyncSessionStore>>
export const SyncSessionStateKey = Symbol() as InjectionKey<Readonly<SyncSessionState>>
