import { IDataPoint, IDataSource, IDataSourceConfig } from "../services/dataProvider"
import { FakeDataGenerator } from "../services/fakeDataGenerator"
import { DashboardConfig } from "../stores/dashboardStore"
import { SpaceConfig } from "../stores/spaceStore"
import { UserConfig } from "../stores/userConfigDataStore"
import { OrgConfig } from "../stores/orgStore"
import { TileConfig } from "../models/tile-models"
import { SyncSessionState } from "../stores/syncSessionStore"

export const LOCALSTORAGE_USER_SETTINGS = "LOCALSTORAGE_USER_SETTINGS"
export const LOCALSTORAGE_SYNCSESSION_STATE = "SYNCSESSION_STATE"
export const LOCALSTORAGE_TILE_SETTINGS_PREFIX = "LOCALSTORAGE_TILE_SETTINGS_"

let fakeDataGenerator = new FakeDataGenerator()

export class PersistenceService {
  async cleanupStorage(tileConfigIds: string[]) {
    for (let key of Object.keys(localStorage)) {
      if (!key.startsWith(LOCALSTORAGE_TILE_SETTINGS_PREFIX)) continue

      const tileConfigId = key.substring(LOCALSTORAGE_TILE_SETTINGS_PREFIX.length)
      if (!tileConfigIds.includes(tileConfigId)) {
        console.warn("cleanup: remove ls key " + LOCALSTORAGE_TILE_SETTINGS_PREFIX + tileConfigId)
        localStorage.removeItem(LOCALSTORAGE_TILE_SETTINGS_PREFIX + tileConfigId)
      }
    }
  }

  async loadUser() {
    // await waitMs(300)

    const configFromStorage = localStorage.getItem(LOCALSTORAGE_USER_SETTINGS)
    if (configFromStorage != null) {
      return JSON.parse(configFromStorage) as UserConfig
    }
    return {
      id: "myUserId",
      name: "Hannes",
      orgs: ["public"],
      hasPicture: false,
      userSettings: {},
      userAgentPrefs: {}
    } as UserConfig
  }

  async saveUser(config: UserConfig) {
    localStorage.setItem(LOCALSTORAGE_USER_SETTINGS, JSON.stringify(config))
  }

  async loadSyncSession() {
    const configFromStorage = localStorage.getItem(LOCALSTORAGE_SYNCSESSION_STATE)
    if (configFromStorage != null) {
      return JSON.parse(configFromStorage) as SyncSessionState
    }
  }

  async persistSyncSession(state: SyncSessionState) {
    localStorage.setItem(LOCALSTORAGE_SYNCSESSION_STATE, JSON.stringify(state))
  }

  async eraseSyncSession() {
    localStorage.removeItem(LOCALSTORAGE_SYNCSESSION_STATE)
  }

  async loadOrg(orgId: string) {
    // await waitMs(200)

    let dbConfig: OrgConfig = {
      id: orgId,
      name: "default",
      hasLogo: false,
      isDefaultOrg: true,
      spaceIds: ["private"]
    }
    return dbConfig
  }

  async loadSpace(spaceId: string) {
    // await waitMs(200)

    let config: SpaceConfig = {
      id: spaceId,
      name: "default",
      isPrivate: true,
      themeColor: undefined,
      hasLogo: false,
      boardIds: ["default", "public"],
      dataSourceIds: [],
      tileConfigIds: []
    }
    return config
  }

  async loadDashboard(dashboardId: string) {
    // await waitMs(100)
    const configFromStorage = localStorage.getItem(`dashbaord_${dashboardId}`)
    if (configFromStorage != null) {
      return JSON.parse(configFromStorage) as DashboardConfig
    }
  }

  async saveDashboard(config: DashboardConfig) {
    localStorage.setItem(`dashbaord_${config.id}`, JSON.stringify(config))
  }

  async loadDataStream(dataStreamId: string) {
    // await waitMs(200)

    let fakeData: IDataPoint[] = []

    const metaData = fakeDataGenerator.getAllMetaData()

    fakeData = await fakeDataGenerator.generateFakeDataById(dataStreamId)
    // console.log(fakeData)

    let dataSourceNames = {}
    metaData.forEach((m) => {
      dataSourceNames[m.id] = m.name
    })

    return {
      id: dataStreamId,
      name: dataSourceNames[dataStreamId],
      dataSourceConfigId: dataStreamId + "_config",
      data: fakeData,
      schema: [
        {
          id: "field1",
          fieldName: "x",
          type: "string"
        },
        {
          id: "field2",
          fieldName: "y",
          type: "number"
        },
        {
          id: "field3",
          fieldName: "y1",
          type: "number"
        }
      ]
    } as IDataSource
  }

  async loadTileConfig(tileConfigId: string) {
    // await waitMs(Math.random() * 1000)

    const configFromStorage = localStorage.getItem(`${LOCALSTORAGE_TILE_SETTINGS_PREFIX}${tileConfigId}`)
    if (configFromStorage != null) {
      console.debug("restored config from cache", JSON.parse(configFromStorage))
      return JSON.parse(configFromStorage) as TileConfig
    }

    const tileConfig = {
      id: tileConfigId,
      title: "",
      widgetType: "none"
    } as TileConfig

    console.debug("loaded config", tileConfig)
    return tileConfig
  }

  async saveTileConfig(config: TileConfig) {
    localStorage.setItem(`${LOCALSTORAGE_TILE_SETTINGS_PREFIX}${config.id}`, JSON.stringify(config))
  }

  async eraseTileConfig(configId: string) {
    localStorage.removeItem(`${LOCALSTORAGE_TILE_SETTINGS_PREFIX}${configId}`)
  }

  async loadDataSourceConfig(dataSourceConfigId: string) {
    // await waitMs(20)
    const locDescriptions = {
      HAM: "Hamburg, Germany",
      FRA: "Frankfurt, Germany",
      MUN: "Munich, Germany",
      DUS: "Düsseldorf, Germany"
    }

    const storeLocation = dataSourceConfigId.includes("2") ? "MUN" : "HAM"
    const fetchLocation = "FRA"

    console.log(dataSourceConfigId)

    return {
      id: dataSourceConfigId,
      url: "https://api.github.com/.....",
      symbol: dataSourceConfigId.includes("github") ? "github" : dataSourceConfigId.includes("azure") ? "azure" : "",
      fetchLocation: fetchLocation,
      fetchLocationDescription: locDescriptions[fetchLocation],
      storeLocation: storeLocation,
      storeLocationDescription: locDescriptions[storeLocation]
    } as IDataSourceConfig
  }
}
