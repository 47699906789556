import { InjectionKey } from "@vue/runtime-core"
import { defineStore, acceptHMRUpdate } from "pinia"
import { deepClone } from "../helpers"
import { PersistenceService } from "../services/persistenceService"
import { SyncSessionService } from "../services/syncSessionService"

/**
 * Enum for valid Tile UI element visiblities.
 */
export const enum TileUiVisibility {
  /** Doesn't show in any state */
  None = "none",
  /** only shows on hover state */
  OnlyHover = "onlyHover",
  /** shows on hover, and if not hovered shows slightly faded */
  NonHoverOpacity = "nonHoverOpacity",
  /** always shows, regardless of hover state */
  Always = "always"
}

export interface DashboardConfig {
  /** ulid of dashboard, contains creation date */
  id: string
  /** dashboard name */
  name: string
  tiles: DashboardTilePositionConfig[]
  /** array of data, should not be mutated */
  // slices: DashboardSliceConfig[]
  sliceIds: string[]
  uiElementVisibilities: {
    title: TileUiVisibility
    symbol: TileUiVisibility
    xAxis: TileUiVisibility
    y1Axis: TileUiVisibility
    /** the markers.. */
    gridLines: TileUiVisibility
  }
}

export interface DashboardTilePositionConfig {
  /** unique id */
  id: string
  /** the slice the tile belongs to */
  sliceId: string
  /** id of tile config */
  tileConfigId?: string
  /** x position of upper left corner */
  x: number
  /** y position of upper left corner */
  y: number
  /** width in base units */
  width: number
  /** height in base units */
  height: number
}

const storeCache: Map<string, ReturnType<typeof createDashboardStore>> = new Map()

let storeInit: DashboardConfig = {
  id: "",
  name: "",
  sliceIds: [],
  tiles: [],
  uiElementVisibilities: {
    title: TileUiVisibility.NonHoverOpacity,
    symbol: TileUiVisibility.NonHoverOpacity,
    xAxis: TileUiVisibility.OnlyHover,
    y1Axis: TileUiVisibility.OnlyHover,
    gridLines: TileUiVisibility.OnlyHover
  }
}

function createDashboardStore(dashboardId: string) {
  const persistenceService = new PersistenceService()
  const syncSessionService = new SyncSessionService()

  const useStore = defineStore({
    id: `dashboard_${dashboardId}`,
    state: () => Object.assign(deepClone(storeInit), { id: dashboardId }),
    getters: {
      getTile: (state) => {
        return (tileId: string) => {
          return state.tiles.filter((t) => t.id === tileId)[0]
        }
      },
      getTilesFromSlice: (state) => {
        return (sliceId: string) => {
          return state.tiles.filter((t) => t.sliceId == sliceId)
        }
      },
      tileCountOfSlices: (state) => {
        return state.sliceIds.map((sliceId) => state.tiles.filter((t) => t.sliceId == sliceId).length)
      },
      lastSliceId: (state) => {
        return state.sliceIds[state.sliceIds.length - 1]
      },
      lastSliceTiles: (state) => {
        const lastSliceId = state.sliceIds[state.sliceIds.length - 1]
        return state.tiles.filter((t) => t.sliceId == lastSliceId)
      }
    },
    actions: {
      addTile(sliceId: string, x: number, y: number, width: number, height: number): string {
        if (!this.sliceIds.includes(sliceId)) {
          throw new Error(`slice ${sliceId} not found`)
        }

        const tileId = "tile_" + Math.random()
        this.tiles.push({
          id: tileId,
          sliceId: sliceId,
          x: x,
          y: y,
          width: width,
          height: height
        })
        return tileId
      },
      deleteTile(tileId: string) {
        const tile = this.tiles.filter((t) => t.id == tileId)[0]
        if (!tile) {
          // throw new Error(`tile ${tileId} not found for deletion`)
          return
        }
        this.tiles.splice(this.tiles.indexOf(tile), 1)
        if (tile.tileConfigId) persistenceService.eraseTileConfig(tile.tileConfigId)
      },
      applyTileUpdate(sliceId: string, tileId: string, x: number, y: number, width: number, height: number) {
        if (!tileId) {
          return
        }

        const tile = this.tiles.filter((t) => t.id == tileId)[0]
        if (!tile) throw new Error(`tile ${tileId} not found`)

        tile.x = x
        tile.y = y
        tile.width = width
        tile.height = height
        tile.sliceId = sliceId
      },
      /** we add "virtual" extra slice on drag/resize */
      addNewSlice() {
        const newId = "id_" + Math.random()
        this.sliceIds.push(newId)
        return newId
      },
      moveSliceToIndex(sliceId: string, index: number) {
        const currentIndex = this.sliceIds.indexOf(sliceId)
        const prevSliceId = this.sliceIds[index]
        this.sliceIds[index] = sliceId
        this.sliceIds[currentIndex] = prevSliceId
      },
      /** when e.g. drag/resize is over, we cleanup all empty slices */
      cleanupSlices() {
        if (this.sliceIds.length > 1 && this.tiles.length > 0) {
          const allSliceIds = new Set(this.tiles.map((t) => t.sliceId))
          const sliceIdsWithTiles = this.sliceIds.filter((sid) => allSliceIds.has(sid))
          if (sliceIdsWithTiles.length !== this.sliceIds.length) {
            this.sliceIds = sliceIdsWithTiles
          }
        }
      },
      /** !! DON'T USE this outside dev environment !! */
      cleanupStoreDangerous() {
        // persistenceService.cleanupStorage(this.tiles.map((t) => t.id!))
      }
    },
    lifecycle: {
      onLoad: async (store) => {
        if (syncSessionService.isSessionClient) {
          return syncSessionService.loadFromSyncStore(store.$id)
        }
        syncSessionService.registerStore(store)
        const state = await persistenceService.loadDashboard(store.$id.substring("dashboard_".length))
        return state
      },
      onPersist: (_, state) => {
        if (!syncSessionService.isSessionClient) persistenceService.saveDashboard(state)
      }
    }
  })
  if (import.meta.hot) {
    // see https://pinia.esm.dev/cookbook/hot-module-replacement.html
    import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
  }

  return useStore()
}

export function getDashboardStore(id: string) {
  if (!storeCache.has(id)) {
    storeCache.set(id, createDashboardStore(id))
  }

  return storeCache.get(id)!
}

SyncSessionService.registerStoreCreation("dashboard_", (storeId) => getDashboardStore(storeId))

export const DashboardStoreKey = Symbol() as InjectionKey<ReturnType<typeof createDashboardStore>>
export const DashboardStateKey = Symbol() as InjectionKey<Readonly<DashboardConfig>>
