import { IDataPoint } from "../services/dataProvider"
import { convertLocal2Utc, drawRandom, getLoremIpsum, getUtcNow } from "../helpers"
import { DataViewItemFieldConfig, ChartConfig } from "../models/tile-models"

interface IValueGenerator {
  generateNextValue(index: number, dataSize: number, seed: number): number | string
}

interface ITimeGenerator {
  generateUtcTimes(dataSize: number, seed: number): number[]
}

interface GenerationFieldOptions {
  fieldName: string
  generator: IValueGenerator
  dataViewConfig?: DataViewItemFieldConfig
  chartConfig?: Partial<ChartConfig>
}

type FakeDataGenerationInfo = {
  id: string
  name: string
  size: number
  timeGenerator: ITimeGenerator
  fields: GenerationFieldOptions[]
}

class DelegateValueGenerator implements IValueGenerator {
  delegate: (index: number, dataSize: number) => string | number

  constructor(delegate: (index: number, dataSize: number) => string | number) {
    this.delegate = delegate
  }

  generateNextValue(index: number, dataSize: number, seed: number): string | number {
    return this.delegate(index, dataSize)
  }
}

class AdditiveDelegateValueGenerator implements IValueGenerator {
  delegate: (index: number, dataSize: number) => number
  last: number
  min: number
  max: number

  constructor(delegate: (index: number, dataSize: number) => number, start: number, min: number, max: number) {
    this.delegate = delegate
    this.last = start
    this.min = min
    this.max = max
  }

  generateNextValue(index: number, dataSize: number, seed: number): string | number {
    const newVal = +this.delegate(index, dataSize)
    let next = Math.min(this.max, Math.max(this.min, this.last + newVal))
    // console.warn(newVal, next)
    this.last = next
    return next
  }
}

class AppendDataValueGenerator implements IValueGenerator {
  lastValues: (number | string)[] = []
  maxValue: number = 0
  minValue: number = 0
  type: "string" | "number" = "string"

  constructor(lastValues: (number | string)[]) {
    this.lastValues = lastValues
    if (typeof lastValues[0] == "number") {
      this.minValue = Math.min(...(lastValues.filter((n) => typeof n == "number") as number[]))
      this.maxValue = Math.max(...(lastValues.filter((n) => typeof n == "number") as number[]))
      if (this.minValue == this.maxValue) {
        this.maxValue = this.minValue + 1
      }
      this.type = "number"
    }
  }

  generateNextValue(index: number, dataSize: number, seed: number): string | number {
    if (index >= dataSize - this.lastValues.length) {
      return this.lastValues[index - (dataSize - this.lastValues.length)]
    }
    if (this.type == "string") {
      return this.lastValues[Math.floor(Math.random() * this.lastValues.length)]
    } else {
      // draw random from min/max
      const range = this.maxValue - this.minValue
      return Math.round(this.minValue + range * Math.random() * 100) / 100
    }
  }
}

class LoremIpsumValueGenerator implements IValueGenerator {
  maxLength: number = 0
  minLength: number = 0

  constructor(minLength: number, maxLength: number) {
    this.minLength = minLength
    this.maxLength = maxLength
  }

  generateNextValue(index: number, dataSize: number, seed: number): string | number {
    return getLoremIpsum(this.minLength + Math.random() * (this.maxLength - this.minLength))
  }
}

class LinearTimeGenerator implements ITimeGenerator {
  timeUnit: number

  constructor(timeUnitInSeconds: number) {
    this.timeUnit = timeUnitInSeconds * 1000
  }

  generateUtcTimes(dataSize: number, seed: number): number[] {
    const utcNow = convertLocal2Utc(+new Date())
    const times: number[] = []

    const endTime = utcNow - (utcNow % this.timeUnit)

    for (let index = dataSize - 1; index >= 0; index--) {
      times.push(endTime - index * this.timeUnit)
    }
    return times
  }
}

class LinearWithGapsTimeGenerator implements ITimeGenerator {
  timeUnit: number
  gapChance: number

  constructor(timeUnitInSeconds: number, gapChance: number) {
    this.timeUnit = timeUnitInSeconds * 1000
    this.gapChance = gapChance
  }

  generateUtcTimes(dataSize: number, seed: number): number[] {
    const utcNow = convertLocal2Utc(+new Date())
    const times: number[] = []

    for (let index = dataSize - 1; index >= 0; index--) {
      if (this.gapChance > Math.random()) continue
      times.push(utcNow - index * this.timeUnit - 1000)
    }
    // console.log(times)
    return times
  }
}

class VariableTimeGenerator implements ITimeGenerator {
  timeUnit: number
  variance: number

  constructor(timeUnitInSeconds: number, variance: number) {
    this.timeUnit = timeUnitInSeconds * 1000
    this.variance = variance
  }

  generateUtcTimes(dataSize: number, seed: number): number[] {
    const utcNow = convertLocal2Utc(+new Date())
    const times: number[] = []

    for (let index = dataSize - 1; index >= 0; index--) {
      let time = utcNow - index * this.timeUnit
      time += this.timeUnit * this.variance * Math.random() * (Math.random() > 0.5 ? 1 : -1)
      times.push(time)
    }
    return times
  }
}

// export type FakeDataTypes = "datasource1_github" | "datasource2_azure" | "datasource2_twitter_autoupdate"

const metaData: FakeDataGenerationInfo[] = []

metaData.push({
  id: "datasource_simple",
  name: "Simple Numbers",
  size: 250,
  timeGenerator: new LinearTimeGenerator(60 * 60),
  fields: [
    {
      fieldName: "y",
      generator: new AppendDataValueGenerator([0.5, 0.4, 0.3, 0.25, 0.45, 0.6, 0.7, 0.4, 0.22, 0.1, 0.15]),
      dataViewConfig: {
        srcField: "y",
        label: "y",
        valueType: "number",
        numberPrecision: 2,
        postFix: " €"
      },
      chartConfig: {
        chartType: "line",
        colorOverride: "#3f51b5",
        fieldName: "y",
        areaOpacity: 0.4
      }
    },
    {
      fieldName: "y1",
      generator: new AppendDataValueGenerator([0.7, 1, 0.75, 0.5, 0, 0.35, 0.42, 0.7, 0.75, 0.65, 0.55]),
      dataViewConfig: {
        srcField: "y1",
        label: "z",
        valueType: "number",
        numberPrecision: 3
      },
      chartConfig: {
        chartType: "bar",
        colorOverride: "#008000",
        fieldName: "y1",
        areaOpacity: 0.9
      }
    },
    {
      fieldName: "x1",
      generator: new DelegateValueGenerator(() => (Math.random() * 66) | 0),
      dataViewConfig: {
        srcField: "x1",
        label: "x1",
        valueType: "number",
        numberPrecision: 1
      }
    },
    {
      fieldName: "description",
      generator: new LoremIpsumValueGenerator(0, 30),
      dataViewConfig: {
        srcField: "description",
        label: "info",
        valueType: "text",
        width: 200
      }
    },
    {
      fieldName: "description2",
      generator: new LoremIpsumValueGenerator(10, 60),
      dataViewConfig: {
        srcField: "description2",
        label: "More infos",
        valueType: "text",
        width: 300
      }
    }
  ]
})

metaData.push({
  id: "datasource1_github",
  name: "Github Commits",
  size: 150,
  timeGenerator: new LinearWithGapsTimeGenerator(60 * 60 * 24, 0.2),
  fields: [
    {
      fieldName: "commitCount",
      generator: new DelegateValueGenerator(() => (Math.random() * 20) | 0),
      dataViewConfig: {
        srcField: "commitCount",
        label: "# of commits",
        valueType: "number"
      },
      chartConfig: {
        atTop: true,
        chartType: "line",
        colorOverride: "#0d6ce7",
        fieldName: "commitCount",
        areaOpacity: 1
      }
    },
    {
      fieldName: "added",
      generator: new DelegateValueGenerator(() => (Math.random() * 40) | 0),
      dataViewConfig: {
        srcField: "added",
        label: "lines +",
        valueType: "number"
      },
      chartConfig: {
        chartType: "bar",
        colorOverride: "#E7820d",
        fieldName: "added",
        areaOpacity: 0.4
      }
    },
    {
      fieldName: "removed",
      generator: new DelegateValueGenerator(() => (Math.random() * 10) | 0),
      dataViewConfig: {
        srcField: "removed",
        label: "lines -",
        valueType: "number"
      },
      chartConfig: {
        chartType: "bar",
        colorOverride: "#11e70d",
        fieldName: "removed",
        areaOpacity: 0.4
      }
    }
  ]
})

metaData.push({
  id: "datasource2_azure",
  name: "Azure costs",
  size: 250,
  timeGenerator: new LinearWithGapsTimeGenerator(60 * 60 * 24, 0.15),
  fields: [
    {
      fieldName: "cost",
      generator: new AdditiveDelegateValueGenerator(() => Math.random() * 150 - 75, 1200, 100, 2500),
      dataViewConfig: {
        srcField: "cost",
        label: "",
        valueType: "number",
        numberPrecision: 2,
        postFix: " €"
      },
      chartConfig: {
        chartType: "bar",
        colorOverride: "#4050B0",
        fieldName: "cost",
        areaOpacity: 1
      }
    },
    {
      fieldName: "cost_compute",
      generator: new AdditiveDelegateValueGenerator(() => Math.random() * 30 - 15, 800, 100, 1000),
      dataViewConfig: {
        srcField: "cost_compute",
        label: "Compute",
        valueType: "number",
        numberPrecision: 2,
        postFix: " €"
      }
    }
  ]
})

metaData.push({
  id: "datasource2_twitter_autoupdate",
  name: "Random Number Generator",
  size: 40,
  timeGenerator: new LinearTimeGenerator(5),
  fields: [
    {
      fieldName: "y",
      generator: new DelegateValueGenerator(() => (Math.random() * 400) | 0),
      dataViewConfig: {
        srcField: "y",
        label: "y",
        valueType: "number",
        numberPrecision: 2,
        postFix: " $"
      },
      chartConfig: {
        chartType: "line",
        colorOverride: "#3f51b5",
        fieldName: "y",
        areaOpacity: 0.4
      }
    },
    {
      fieldName: "y1",
      generator: new DelegateValueGenerator(() => (Math.random() * 4000) | 0),
      dataViewConfig: {
        srcField: "y1",
        label: "z",
        valueType: "number",
        numberPrecision: 3,
        postFix: " %"
      },
      chartConfig: {
        chartType: "line",
        colorOverride: "#007200",
        fieldName: "y1"
      }
    }
  ]
})

metaData.push({
  id: "twitter_feed",
  name: "Tweets for #yeah",
  size: 40,
  timeGenerator: new VariableTimeGenerator(120, 0.5),
  fields: [
    {
      fieldName: "message",
      generator: new LoremIpsumValueGenerator(80, 180),
      dataViewConfig: {
        srcField: "message",
        label: "Message",
        valueType: "text"
      }
    },

    {
      fieldName: "link",
      generator: new DelegateValueGenerator(() => "https://mobile.twitter.com/tweet/status/1465053672593784834"),
      dataViewConfig: {
        srcField: "link",
        label: "Link",
        valueType: "uriLink"
      }
    },
    {
      fieldName: "retweets",
      generator: new DelegateValueGenerator(() => (Math.random() * 120) | 0),
      dataViewConfig: {
        srcField: "retweets",
        label: "Retweets",
        valueType: "number"
      },
      chartConfig: {
        chartType: "line",
        colorOverride: "#007200",
        fieldName: "retweets"
      }
    },
    {
      fieldName: "userLink",
      generator: new DelegateValueGenerator(() => "https://mobile.twitter.com/tweet"),
      dataViewConfig: {
        srcField: "userLink",
        label: "User",
        valueType: "uriLink",
        linkTextField: "user"
      }
    },
    {
      fieldName: "pic",
      generator: new DelegateValueGenerator(() =>
        drawRandom([
          "https://images.unsplash.com/photo-1644580841272-8db1ba31b347?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0Njg0NTIxMg&ixlib=rb-1.2.1&q=80&w=300",
          "https://images.unsplash.com/photo-1644868731762-e9a9443667a0?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0Njg0NTIxNg&ixlib=rb-1.2.1&q=80&w=300",
          "https://images.unsplash.com/photo-1646676172120-35af24c22e39?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0Njg0NTIxOA&ixlib=rb-1.2.1&q=80&w=300",
          "https://images.unsplash.com/photo-1646294614428-dbd9c5d8069c?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0Njg0NTIyMA&ixlib=rb-1.2.1&q=80&w=300",
          "https://images.unsplash.com/photo-1644783850644-d701d48c4228?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0Njg0NTIyMg&ixlib=rb-1.2.1&q=80&w=300",
          "https://images.unsplash.com/photo-1645301565223-9ad770d06a16?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0Njg0NTIyNA&ixlib=rb-1.2.1&q=80&w=300",
          "https://images.unsplash.com/photo-1646026371686-79950ceb6daa?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0Njg0NTIyNg&ixlib=rb-1.2.1&q=80&w=300"
        ])
      ),
      dataViewConfig: {
        srcField: "pic",
        label: "Pic",
        valueType: "imageLink"
      }
    },
    {
      fieldName: "user",
      generator: new DelegateValueGenerator(() =>
        Math.random() < 0.2 ? "@hannesk" : Math.random() < 0.5 ? "@tobiw" : "@seb1987"
      )
    }
  ]
})

/**
 * Generate fake data based on certain parameters
 */
export class FakeDataGenerator {
  generateData(
    size: number,
    /** generates UTC time stamps */
    timeCreation: ITimeGenerator,
    fields: GenerationFieldOptions[],
    options?: {
      seed?: number
    }
  ): IDataPoint[] {
    const seed = options?.seed || Math.random()

    const dataPoints: IDataPoint[] = []
    const utcNow = getUtcNow()

    const times = timeCreation.generateUtcTimes(size, seed).filter((t) => t <= utcNow)

    for (let index = 0; index < times.length; index++) {
      let dp: IDataPoint = {
        timeUtc: times[index],
        values: {}
      }
      fields.forEach((field) => {
        dp.values[field.fieldName] = field.generator.generateNextValue(index, size, seed)
      })

      dataPoints.push(dp)
    }

    // ensure data is sorted according to time, latest last
    dataPoints.sort((a, b) => (a.timeUtc < b.timeUtc ? -1 : 1))
    // console.log(dataPoints)

    return dataPoints
  }

  getAllMetaData(): FakeDataGenerationInfo[] {
    return metaData
  }

  getMetaDataForSet(dataSetId: string | undefined): FakeDataGenerationInfo | undefined {
    const dataSetData = metaData.find((m) => m.id == dataSetId)
    // if (!dataSetData) throw new Error(`couldn't find dataset for id ${dataSetId}`)

    return dataSetData
  }

  async generateFakeDataById(dataSetId: string | undefined): Promise<IDataPoint[]> {
    const metaData = this.getMetaDataForSet(dataSetId)
    if (!metaData) return []

    return this.generateData(metaData.size, metaData.timeGenerator, metaData.fields)
  }
}
