import { watch } from "vue"
import { SubscriptionCallback } from "pinia"
import { useWebSocket, useDebounceFn } from "@vueuse/core"
import { getSyncSessionStore, ServerEvent, SessionInfo } from "../stores/syncSessionStore"
import { waitFor, waitMs } from "../helpers"
import { PersistenceService } from "./persistenceService"

interface SyncEvent {
  storeId: string
  state: any
}

interface IStore {
  $id: string
  $state: any
  $subscribe: (callback: SubscriptionCallback<any>) => () => void
}

interface IStoreCreator {
  prefix: string
  creationCallback: (entityId: string) => IStore
}

interface ClientEvent {
  timestamp: number
  message: string
  quit: string
  /** denotes type, init has userAgent in message */
  type: "init" | "sync" | "close"
}

let syncStore: ReturnType<typeof getSyncSessionStore>
let persistenceService = new PersistenceService()
let storeCreators: IStoreCreator[] = []

let sendUpdateMessageFn: (data: string | ArrayBuffer | Blob, useBuffer?: boolean) => boolean
let closeWebsocketFn: () => void

let watchs: (() => void)[] = []
let storeSubcriptions: (() => void)[] = []
/** all the stores we either subscribe to when starting syncing, or write to receiving sync events */
let storeMap: Map<string, IStore> = new Map()

export class SyncSessionService {
  constructor() {
    syncStore = getSyncSessionStore()
    // we cheat: we know load here is synchronous
    syncStore.__loadState()
  }

  get isSessionActive() {
    return !!syncStore.sessionId
  }

  get isSessionHost() {
    return this.isSessionActive && syncStore.isSessionHost
  }

  get isSessionClient() {
    return this.isSessionActive && !syncStore.isSessionHost
  }

  static registerStoreCreation(prefix: string, creationCallback: (storeId: string) => IStore) {
    storeCreators.push({
      prefix: prefix,
      creationCallback: creationCallback
    })
  }

  async startNewSyncSession() {
    if (syncStore.isSessionActive) {
      await this.stopSyncSession()
    }
    syncStore.sessionStarted = true

    const response = await fetch("/api/sync/new", {
      method: "POST"
    })

    if (!response.ok) {
      throw new Error("could not fetch sync id")
    }
    syncStore.sessionId = await response.text()
    syncStore.isSessionHost = true

    await this.setupConnection(syncStore.sessionId)

    persistenceService.persistSyncSession(syncStore.$state)

    console.warn(storeMap)

    storeMap.forEach((store) => {
      this.startStoreSubscription(store)
    })
  }

  async joinSyncSession(sessionId: string) {
    syncStore.sessionId = sessionId

    await this.setupConnection(sessionId)

    if (syncStore.isSessionHost) {
      storeMap.forEach((store) => {
        this.startStoreSubscription(store)
      })
    } else {
      // load last state from server
      const response = await fetch(`/api/sync/${syncStore.sessionId}/lastState`)
      const allStores = (await response.json()) as SyncEvent[]
      console.warn("all stores", allStores)

      allStores.forEach((storeState) => {
        const store = this.getOrCreateStore(storeState.storeId)
        store.$state = storeState.state
        storeMap.set(storeState.storeId, store)
      })
    }
  }

  async setupConnection(sessionId: string) {
    const wsProtocol = location.protocol == "https:" ? "wss:" : "ws:"
    const { status, data, send, close, open } = useWebSocket(
      `${wsProtocol}//${location.host}/api/sync/${sessionId}/websocket`,
      {
        // means we don't have to call open
        immediate: false,
        onError: (ws, event) => console.error(event),
        autoReconnect: {
          retries: 3,
          delay: 5000,
          onFailed: () => {
            console.error("retry 3 times failed")
          }
        },
        heartbeat: {
          message: "ping",
          interval: 30000
        }
      }
    )
    console.debug("connected ws")

    sendUpdateMessageFn = send
    closeWebsocketFn = close

    const stop = watch(
      () => status.value,
      (s) => {
        console.debug("ws status changed", s)
        syncStore.wsStatus = s
      },
      { immediate: true }
    )
    watchs.push(stop)

    const stop2 = watch(
      () => data.value,
      async (data) => {
        console.debug("ws new message")

        const event = JSON.parse(data) as ServerEvent
        if (event.errorCode) {
          switch (event.errorCode) {
            case 1:
              console.error("wrong session id")
              this.stopSyncSession()
              return
            case 3:
              console.error("session closed by host")
              this.stopSyncSession()
              return
          }
        }

        syncStore.lastEvent = event

        switch (event.type) {
          case "initclient":
            console.debug("clientId received", event.message)
            syncStore.clientId = event.message
            persistenceService.persistSyncSession(syncStore.$state)
            break
          case "syncupdate":
            // not interested in own sync.. shouldn't ever happen, but being defensive here
            if (syncStore.isSessionHost) return
            // console.log("new update", event.message)
            const syncEvent = JSON.parse(event.message) as SyncEvent
            let store = storeMap.get(syncEvent.storeId)
            if (!store) {
              store = this.getOrCreateStore(syncEvent.storeId)
              storeMap.set(syncEvent.storeId, store)
            }
            store.$state = syncEvent.state
            break
          case "clientsupdate":
            syncStore.connectedClients = JSON.parse(event.message) as SessionInfo[]
            persistenceService.persistSyncSession(syncStore.$state)
            break
          case "sessionclosed":
            console.warn("session was closed!")
            // todo: show info
            this.stopSyncSession()
            break
        }
      }
    )
    watchs.push(stop2)

    open()

    await waitFor(() => syncStore.wsStatus == "OPEN", 5000)

    console.warn("clientId", syncStore.clientId)

    const payload = JSON.stringify({
      timestamp: +new Date(),
      // we use previous clientId if still exists to "rejoin" if possible
      message: syncStore.clientId,
      type: "init"
    } as ClientEvent)
    console.log("payload", payload)

    await waitMs(200)

    send(payload, false)
  }

  getOrCreateStore(storeId: string) {
    const storeCreator = storeCreators.find((creator) => storeId.startsWith(creator.prefix))
    if (!storeCreator) {
      throw new Error(`no prefix registered for storeId ${storeId}`)
    }
    return storeCreator.creationCallback(storeId.replace(storeCreator.prefix, ""))
  }

  sendStoreState(store: IStore) {
    // console.warn("send state", store.$id)
    sendUpdateMessageFn?.(
      JSON.stringify({
        timestamp: +new Date(),
        message: JSON.stringify({
          storeId: store.$id,
          state: store.$state
        } as SyncEvent),
        type: "sync"
      } as ClientEvent)
    )
  }

  /** We start subscribing to a store and sending the store state to the backend when starting a session
   * As we need to automatically detect changes on store changes
   */
  async startStoreSubscription(store: IStore) {
    // first we send store state to backend
    this.sendStoreState(store)

    // then we subscribe to changes, we also throttle event sending
    const subscription = store.$subscribe(
      useDebounceFn(
        (mutation, state) => {
          // console.warn("store " + mutation.storeId + " was changed", mutation.type, JSON.stringify(state))
          this.sendStoreState(store)
        },
        300,
        {
          maxWait: 1000
        }
      )
    )
    storeSubcriptions.push(subscription)
  }

  async stopSyncSession() {
    if (!syncStore.sessionId) {
      console.warn("tried to stop session but no sessionId found")
      return
    }

    closeWebsocketFn?.()
    syncStore.$reset()

    watchs.forEach((watchStopCallback) => watchStopCallback())
    watchs = []

    storeSubcriptions.forEach((stopStoreSubscriptionCallback) => stopStoreSubscriptionCallback())
    storeSubcriptions = []

    storeMap.clear()

    new PersistenceService().eraseSyncSession()
    // console.warn(JSON.stringify(syncStore.$state))

    // load back old sync session
    const dashboardCreator = storeCreators.find((c) => c.prefix == "dashboard_")!
    const boardStore = dashboardCreator.creationCallback("board1")
    //@ts-ignore
    await boardStore.__loadState()
  }

  /** we add a store to the collection and load from external API
   * then when event indicates id for this store was updated, we mutate the pinia store completly
   */
  registerStore(store: IStore) {
    if (this.isSessionClient) {
      // we don't want to register remote-hosted stores for listening, that's for the host
      return
    }

    storeMap.set(store.$id, store)

    // is session is ongoing and we are the host: directly subscribe and send store state
    if (syncStore.isSessionActive && syncStore.isSessionHost) {
      this.startStoreSubscription(store)
    }
  }

  async loadFromSyncStore(storeId: string) {
    if (!this.isSessionClient) {
      // we don't want to load as a non-connected client
      return
    }

    const store = storeMap.get(storeId)
    if (!store) {
      // todo: fetch from backend
      throw new Error("store not found")
    }

    return store.$state
  }
}
