// inspired by useRafFn by vueuse: https://github.com/vueuse/vueuse/blob/main/packages/core/useRafFn/index.ts
// but extended for more control and usage of time in raf callback
import { ref } from "vue"
import { tryOnScopeDispose } from "@vueuse/core"

export interface RafFnOptions {
  /**
   * Start the requestAnimationFrame loop immediately on creation
   *
   * @default true
   */
  immediate?: boolean
}

/**
 * Call function on every `requestAnimationFrame`. With controls of pausing and resuming.
 */
export function useRafFn(fn: FrameRequestCallback, options: RafFnOptions = {}) {
  const { immediate = true } = options

  const isActive = ref(false)

  function loop(time: number) {
    if (!isActive.value) return

    fn(time)
    window.requestAnimationFrame(loop)
  }

  function resume() {
    if (!isActive.value && window) {
      isActive.value = true
      loop(performance.now())
    }
  }

  function pause() {
    isActive.value = false
  }

  if (immediate) resume()

  tryOnScopeDispose(pause)

  return {
    isActive,
    pause,
    resume
  }
}
