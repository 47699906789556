import { InjectionKey, reactive, ref } from "@vue/runtime-core"
import { Ref } from "@vue/runtime-core"

export interface IUserAgentState {
  /** id of datasource config; can be used by multiple data sources */
  isTouch: boolean
}

export function getUserAgentState(): IUserAgentState {
  // todo: move into injected global uiState
  const mql = window.matchMedia("(hover: none)")
  // wouldn't need to be ref, but easier for development, and maybe prevents future edge cases
  let isTouch = ref(mql.matches!)
  mql.onchange = (e) => (isTouch.value = e.matches)

  return reactive({
    isTouch: isTouch
  })
}

export const UserAgentStateKey = Symbol() as InjectionKey<IUserAgentState>
