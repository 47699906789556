import type { PiniaPluginContext } from "pinia"
import { nextTick, ShallowReactive, watch } from "vue"
import { PeristenceOperationStatus } from "../models/persistence-models"

type PStore = PiniaPluginContext["store"]
type Options = PiniaPluginContext["options"]

function cloneData(data: object): object {
  // see https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
  // only usable in FF, will come to Chrome 98
  //@ts-ignore
  // if(window.structuredClone) {
  //   console.log(data)
  //   //@ts-ignore
  //   return window.structuredClone(data)
  // }
  return JSON.parse(JSON.stringify(data))
}

/**
 * Clones and strips special properties from the store state clone.
 * @param options The options object defining the store passed to `defineStore()`.
 * @param data The state of the store the plugin is augmenting.
 * @returns {Object} State of the store without omitted keys.
 */
function cloneAndStripData(options: Options, data: object): object {
  const clone = cloneData(data)
  if (options.lifecycle?.excludedPersistenceProps) {
    options.lifecycle.excludedPersistenceProps.forEach((key) => {
      delete clone[key]
    })
  }
  return clone
}

/**
 * Adds lifecycle properties for load/reload/snapshot/persist to your store.
 *
 * @example
 *
 * ```ts
 * import { PiniaPersistence } from 'pinia-persistence'
 *
 * // Pass the plugin to your application's pinia plugin
 * pinia.use(PiniaPersistence)
 * ```
 */
export function PiniaLifecycle({ store, options }: PiniaPluginContext) {
  let isLoading = false,
    preventWatchUpdateFromLoad = false
  let lastLoadTime: number, loadingPromise: any
  let clearUpdateWatch: any

  // console.log('PiniaLifecycle for store', store.$id)

  if (options.lifecycle?.onUpdate || options.lifecycle?.persistOnUpdate == true) {
    let watcherFunc: any
    if (options.lifecycle?.excludedPersistenceProps) {
      const watchedKeys = Object.keys(store.$state).filter(
        (key) => !options.lifecycle?.excludedPersistenceProps?.includes(key)
      )
      watcherFunc = () => watchedKeys.map((key) => store.$state[key])
    } else {
      watcherFunc = () => store.$state
    }

    clearUpdateWatch = watch(
      watcherFunc,
      (state, prevState) => {
        if (options.lifecycle?.persistOnUpdate && store.__wasLoaded()) {
          if (options.lifecycle?.onPersist) {
            store.__persistState()
          } else {
            console.error("autopersist is true, but onPersist callback not set")
          }
        }
        if (preventWatchUpdateFromLoad) {
          return false
        }
        options.lifecycle?.onUpdate?.(store, state, prevState)
      },
      {
        deep: true
      }
    )
  }

  store.__getLoadStatus = () => {
    const status = options.lifecycle?.onGetLoadStatus?.(store)
    if (status) {
      return status
    } else {
      throw new Error(`store ${store.$id}: usage of getLoadStatus without defined lifecycle.onGetLoadStatus on store`)
    }
  }

  store.__getPersistStatus = () => {
    const status = options.lifecycle?.onGetPersistStatus?.(store)
    if (status) {
      return status
    } else {
      throw new Error(
        `store ${store.$id}: usage of getPersistStatus without defined lifecycle.onGetPersistStatus on store`
      )
    }
  }

  store.__isLoading = () => isLoading

  store.__wasLoaded = () => !!lastLoadTime

  store.__loadState = async () => {
    if (loadingPromise) {
      return loadingPromise
    }

    if (!options.lifecycle?.onLoad) {
      throw new Error(`load called on store ${store.$id} without loadCallback set`)
    }

    // console.log('__loadState for store', store.$id)

    if (store.__wasLoaded()) {
      // console.log('__loadState wasLoaded() true for store', store.$id)
      return Promise.resolve()
    }

    isLoading = true

    let loadResponse = options.lifecycle?.onLoad(store)
    preventWatchUpdateFromLoad = !!options.lifecycle?.onUpdate

    if (!(loadResponse instanceof Promise)) {
      loadResponse = Promise.resolve(loadResponse)
    }

    const state: object | undefined = await loadResponse

    if (!state) {
      // nothing in store, we keep default state
      lastLoadTime = +new Date()
      isLoading = false
      return
    }

    const strippedState = cloneAndStripData(options, state)
    if (preventWatchUpdateFromLoad) {
      nextTick().then(() => {
        nextTick().then(() => {
          preventWatchUpdateFromLoad = false
        })
      })
    }

    store.$patch(strippedState)
    lastLoadTime = +new Date()
    isLoading = false

    options.lifecycle?.afterLoad?.(store)

    return loadingPromise
  }

  store.__persistState = async () => {
    if (options.lifecycle?.onPersist) {
      const strippedState = cloneAndStripData(options, store.$state)
      await options.lifecycle?.onPersist(store, strippedState)
    } else {
      throw new Error(`persist called on store ${store.$id} without persistCallback set`)
    }
  }

  store.$dispose = () => {
    console.debug(`dispose store ${store.$id}`)
    clearUpdateWatch?.()
  }
}

declare module "pinia" {
  export interface PiniaCustomProperties {
    /**
     * Loads the state from loadCallback. Errors when no loadCallback is set
     */
    __loadState: () => Promise<void>
    /**
     * Persists the state with persistCallback. Errors when no persistCallback is set
     * Will stop active snapshotting, so use to finalizte snapshot edit hirsory, if enabled
     */
    __persistState: () => Promise<void>
    /**
     * Returns boolean whether load() was invoked successfully before
     */
    __isLoading: () => boolean
    /**
     * Returns boolean whether load() was invoked successfully before
     */
    __wasLoaded: () => boolean
    /**
     * Returns reactive state object regarding loading operation
     * Invoke before load() to track async loading state
     */
    __getLoadStatus: () => ShallowReactive<PeristenceOperationStatus>
    /**
     * Returns reactive state object regarding persistence operation
     * Invoke before persist() to track async loading state
     */
    __getPersistStatus: () => ShallowReactive<PeristenceOperationStatus>
  }

  export interface DefineStoreOptionsBase<S, Store> {
    /**
     * Disable or ignore specific fields.
     *
     * @example
     *
     * ```js
     * lifecycle({
     *   id: 'counter',
     *   state: () => ({ count: 0, foo: 'bar' })
     *   persistence: {
     *     // An array of fields that the plugin will ignore.
     *     persistExcludeProps: ['foo'],
     *     // load store from certain method
     *     onLoad: (store) => loadService.loadCounterStore(store.$id)
     *   }
     * })
     * ```
     */
    // actions: {},
    lifecycle?: {
      /** callback that's invoked on store's first initialization or load() and reload() */
      onLoad?: (store: PStore) => Promise<S | undefined>
      /** callback that's invoked after store is loaded */
      afterLoad?: (store: PStore) => void
      /** callback that's invoked on store's persist() */
      onPersist?: (store: PStore, state: S) => Promise<void> | void
      /** callback that's invoked on store's first initialization or load() and reload() */
      onUpdate?: (store: PStore, state: S, prevState: S) => Promise<void> | void
      /** callback that's invoked on getLoadStatus() */
      onGetLoadStatus?: (store: PStore) => PeristenceOperationStatus
      /** callback that's invoked on getPersistStatus() */
      onGetPersistStatus?: (store: PStore) => PeristenceOperationStatus
      /** the props that should be excluded from loading and persisting, but NOT snapshotting */
      excludedPersistenceProps?: Array<keyof S>
      /** whether changes to state should automatically invoke onPersist.
       * Will error in console when onPerist callback was not set */
      persistOnUpdate?: boolean
    }
  }
}
