import type { PiniaPluginContext } from "pinia"
import { ref, Ref } from "vue"

type Store = PiniaPluginContext["store"]
type Options = PiniaPluginContext["options"]

function createStack<T>(current: T) {
  let stack = [current]

  let index = stack.length

  function update() {
    current = JSON.parse(JSON.stringify(stack[index - 1]))

    return current
  }

  return {
    canUndo: () => index > 1,
    canRedo: () => index < stack.length,
    push: (value: T | ((payload: T) => T)) => {
      stack.length = index
      // @ts-expect-error: Value can be a function
      stack[index++] = value

      return update()
    },
    undo: () => {
      if (index > 1) {
        index -= 1
        return update()
      }
    },
    redo: () => {
      if (index < stack.length) {
        index += 1
        return update()
      }
    },
    reset: () => {
      index = 1
      const firstVal = update()
      stack = []
      return firstVal
    }
  }
}

/**
 * Removes properties from the store state.
 * @param options The options object defining the store passed to `defineStore()`.
 * @param store The store the plugin is augmenting.
 * @returns {Object} State of the store without omitted keys.
 */
function removeOmittedKeys(options: Options, store: Store): Store["$state"] {
  const clone = JSON.parse(JSON.stringify(store.$state))
  // if (options.undo && options.undo.omit) {
  //   options.undo.omit.forEach((key) => {
  //     delete clone[key]
  //   })
  //   return clone
  // }
  return clone
}

/**
 * Adds Undo/Redo properties to your store.
 *
 * @example
 *
 * ```ts
 * import { PiniaUndo } from 'pinia-undo'
 *
 * // Pass the plugin to your application's pinia plugin
 * pinia.use(PiniaUndo)
 * ```
 */
export function PiniaUndo({ store, options }: PiniaPluginContext) {
  let stack: ReturnType<typeof removeOmittedKeys>
  let subscription: any
  let canUndo: Ref<boolean>, canRedo: Ref<boolean>

  let preventUpdateOnSubscribe = false
  store.undo = () => {
    preventUpdateOnSubscribe = true
    const preVal = stack.undo()
    if (preVal) store.$patch(preVal)
    canUndo.value = stack.canUndo()
    canRedo.value = stack.canRedo()
  }
  store.redo = () => {
    preventUpdateOnSubscribe = true
    const nextVal = stack.redo()
    if (nextVal) store.$patch(nextVal)
    canUndo.value = stack.canUndo()
    canRedo.value = stack.canRedo()
  }
  store.startUndo = () => {
    stack = createStack(removeOmittedKeys(options, store))
    canUndo = canUndo || ref()
    canRedo = canRedo || ref()
    canUndo.value = false
    canRedo.value = false

    subscription = store.$subscribe(
      () => {
        if (preventUpdateOnSubscribe) {
          preventUpdateOnSubscribe = false
          return
        }
        stack.push(removeOmittedKeys(options, store))
        canUndo.value = stack.canUndo()
        canRedo.value = stack.canRedo()
      },
      {
        flush: "pre"
      }
    )
    return {
      canUndo,
      canRedo
    }
  }
  store.resetUndo = () => {
    const firstVal = stack.reset()
    store.$patch(firstVal)
    subscription?.()
  }
  store.finishUndo = () => {
    subscription?.()
  }
}

declare module "pinia" {
  export interface PiniaCustomProperties {
    /**
     * Undo/Redo a state.
     *
     * @example
     *
     * ```ts
     * const counterStore = useCounterStore()
     *
     * counterStore.increment();
     * counterStore.undo();
     * counterStore.redo();
     * ```
     */
    startUndo: () => {
      canUndo: Ref<boolean>
      canRedo: Ref<boolean>
    }
    undo: () => void
    redo: () => void
    resetUndo: () => void
    finishUndo: () => void
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface DefineStoreOptionsBase<S, Store> {
    /**
     * Disable or ignore specific fields.
     *
     * @example
     *
     * ```js
     * defineStore({
     *   id: 'counter',
     *   state: () => ({ count: 0, foo: 'bar' })
     *   undo: {
     *     // An array of fields that the plugin will ignore.
     *     omit: ['name'],
     *     // Disable history tracking of this store.
     *     disable: true
     *   }
     * })
     * ```
     */
    undo?: {
      // omit?: Array<keyof S>
    }
  }
}
