import { ITimeRange, TimeUnit } from "./services/dataProvider"

export async function waitFor(condition: () => boolean, timeout: number = 2000) {
  if (condition()) {
    return Promise.resolve()
  }
  var startTime = Date.now()

  return new Promise((resolve) => {
    function checkCondition() {
      if (condition()) {
        resolve(true)
        return
      }
      if (Date.now() > startTime + timeout) {
        resolve(false)
        return
      }
      window.setTimeout(checkCondition, 10)
    }
    checkCondition()
  })
}

export async function waitMs(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function deepClone<T>(data: T): T {
  // see https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
  // only usable in FF, will come to Chrome 98
  //@ts-ignore
  // if (window.structuredClone) {
  //   //@ts-ignore
  //   return window.structuredClone(data)
  // }
  return JSON.parse(JSON.stringify(data))
}

export function assertNever(_obj: never) {
  // throw new Error(`doesn't implement DataProvider`)
}

export function isIOS() {
  /*
      Returns (true/false) whether device agent is iOS Safari
  */
  var ua = navigator.userAgent
  var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  var webkitUa = !!ua.match(/WebKit/i)

  return typeof webkitUa !== "undefined" && iOS && webkitUa && !ua.match(/CriOS/i)
}

export function isSafari() {
  // from https://stackoverflow.com/a/44662903/830213
  return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)
}

export function isChrome() {
  // from https://stackoverflow.com/a/67009825/830213
  // @ts-ignore
  return !!window.chrome
}

function getTimeUnitMs(unit: TimeUnit): number {
  switch (unit) {
    case "second":
      return 1000
    case "minute":
      return 1000 * 60
    case "hour":
      return 1000 * 60 * 60
    case "day":
      return 1000 * 60 * 60 * 24
    case "week":
      return 1000 * 60 * 60 * 24 * 7
    case "month":
      return 1000 * 60 * 60 * 24 * 30
    case "year":
      return 1000 * 60 * 60 * 24 * 30 * 12
  }
}

export function createTimeRangeFromNowBack(amount: number, unit: TimeUnit): ITimeRange {
  return {
    endType: "now",
    startType: "relative2end",
    unitType: unit,
    unitAmount: amount
  } as ITimeRange
}

export function getUtcTimeFromRange(timeRange: ITimeRange): [number, number] {
  console.log(timeRange)
  if (!timeRange) debugger
  if (timeRange.endType !== "now" || timeRange.startType !== "relative2end") {
    // todo
    throw new Error(
      `not implemented: timerange startType ${timeRange.startType} or endType ${timeRange.endType} is not supported`
    )
  }

  const utcNow = convertLocal2Utc(+new Date())
  const timeRangeMs = getTimeUnitMs(timeRange.unitType) * timeRange.unitAmount!

  return [utcNow - timeRangeMs, utcNow]
}

/** from https://gist.github.com/codeguy/6684588 */
export function string2Slug(str: string): string {
  str = str.replace(/^\s+|\s+$/g, "") // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaeeeeiiiioooouuuunc------"
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes

  return str
}

export function isDigit(c: string) {
  return c >= "0" && c <= "9"
}

export function round(value: number, precision: number) {
  return Math.round(value * 10 ** precision) / 10 ** precision
}

/* Randomize array in-place using Durstenfeld shuffle algorithm 
  from https://stackoverflow.com/a/12646864 */
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

const timeZoneOffsetToUTC = new Date().getTimezoneOffset() * 60000

export function convertUtc2Local(utcTime: number) {
  return utcTime - timeZoneOffsetToUTC
}

export function convertLocal2Utc(localTime: number) {
  return localTime + timeZoneOffsetToUTC
}

export function getUtcNow() {
  return convertLocal2Utc(+new Date())
}

export function drawRandom(values: string[]) {
  const random = Math.random()
  const chancePerItem = 1 / values.length
  const index = (random / chancePerItem) | 0
  return values[index]
}

export function isRelativeTimeLessThan1Day(timeUtc: number | Date) {
  if (timeUtc instanceof Date) {
    timeUtc = +timeUtc
  }
  const utcNow = convertLocal2Utc(+new Date())
  return utcNow - timeUtc < 24 * 3600 * 1000
}

export function getLoremIpsum(charLength: number) {
  return "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, ".substring(
    0,
    charLength
  )
}

export function createCustomEvent(eventName: string, payload: object): CustomEvent {
  return new CustomEvent(eventName, {
    detail: payload,
    bubbles: true
  })
}
