import { TransitionPresets, noop } from "@vueuse/core"
import { ref, Ref } from "vue"
import { getUtcNow, waitFor } from "../helpers"
import { ITimed } from "../services/dataProvider"
import { EasingFunction, useTransitionFn } from "./useTransitionFn"

export interface TimelineControl {
  init(startUtc: number, endUtc: number): Promise<void>
  move(by: number, skipTransition?: boolean): Promise<void>
  moveToNow(skipTransition?: boolean): Promise<void>
  updateMin(utcTime: number, skipTransition?: boolean): Promise<number>
  updateMax(utcTime: number, skipTransition?: boolean): Promise<number>
  pan(byTime: number): void
  stopPan(): void
  // todo: zoom? for pan/gesture zooming, based on relative x and zoom degree
}

interface TimeControlReturn {
  atNow: Readonly<Ref<boolean>>
  atStart: Readonly<Ref<boolean>>
  isPanning: Readonly<Ref<boolean>>
  timeControl: TimelineControl
  startTimeUtc: Readonly<Ref<number>>
  endTimeUtc: Readonly<Ref<number>>
  timeRange: Readonly<Ref<number>>
  translateTimeBy: Readonly<Ref<number>>
  translateFactor: Readonly<Ref<number>>
  times: Readonly<Ref<number[]>>
}

/**
 * Allows control of time for chart zooming/panning etc.
 * @returns several composable functions to control time of chart, plus some computed states
 */
export function useTimelineControl(
  data: Ref<ITimed[]>,
  fetchDataFn: (startUtc: number, endUtc: number) => Promise<ITimed[]>
) {
  let atNow = ref(false)
  let atStart = ref(false)
  let isPanning = ref(false)

  // these 3 belong together: final visual start/end time is always modified by translateTimeBy
  // for move ops we only translate the "translateTimeBy" value so to be more efficient
  // chart zoom still works by transitioning only start or end (or both)
  let startTimeUtc = ref(0)
  let endTimeUtc = ref(0)
  let timeRange = ref(0)
  let translateTimeBy = ref(0)
  let translateFactor = ref(0)

  let times: Ref<number[]> = ref([])

  let lastTimesStart, lastTimesEnd
  let isTransitioning = false
  let updatesQueued = 0

  function getTimeBucket(startUtc: number, endUtc: number) {
    const timeBucketSize = timeRange.value
    const bufferZone = timeRange.value * 0.1
    const timesStart = startUtc - bufferZone - ((startUtc - bufferZone) % timeBucketSize)
    const timesEnd = endUtc + bufferZone + (timeBucketSize - ((endUtc + bufferZone) % timeBucketSize))
    return [timesStart, timesEnd]
  }

  async function updateData(startUtc: number, endUtc: number) {
    if (startUtc >= endUtc) return []

    const [bucketStartUtc, bucketEndUtc] = getTimeBucket(startUtc, endUtc)

    if (!data.value.length) {
      const newData = await fetchDataFn(bucketStartUtc, Math.min(getUtcNow(), bucketEndUtc))
      data.value = newData
      times.value = data.value.map((d) => d.timeUtc)
    } else {
      // checks cached data and only fetches relevant time fragments from data source
      if (bucketStartUtc < data.value[0].timeUtc) {
        // lets fetch new data at front
        const firstDataTime = data.value[0].timeUtc
        const newData = await fetchDataFn(bucketStartUtc, firstDataTime - 1)
        if (newData.length) {
          // console.warn("fetched new data")
          data.value = newData.concat(data.value)
          times.value = newData.map((d) => d.timeUtc).concat(times.value)
        }
      }
      if (bucketEndUtc > data.value.at(-1)!.timeUtc) {
        const lastDataTime = data.value.at(-1)!.timeUtc
        const newData = await fetchDataFn(lastDataTime + 1, bucketEndUtc)
        if (newData.length) {
          // console.warn("fetched new data")
          data.value = data.value.concat(newData)
          times.value = times.value.concat(newData.map((d) => d.timeUtc))
        }
      }

      const firstCoordTime = times.value[0]
      const lastCoordTime = times.value.at(-1)!

      if (bucketStartUtc < firstCoordTime) {
        const prepended = data.value
          .filter((d) => d.timeUtc > bucketStartUtc && d.timeUtc < firstCoordTime)
          .map((d) => d.timeUtc)

        if (prepended.length) times.value = prepended.concat(times.value)
      }
      if (endUtc > lastCoordTime) {
        const appended = data.value
          .filter((d) => d.timeUtc > lastCoordTime && d.timeUtc < bucketEndUtc)
          .map((d) => d.timeUtc)

        if (appended.length) times.value = times.value.concat(appended)
      }
    }
  }

  function shrinkTimeCoordinates(startUtc: number, endUtc: number) {
    // update times array: grow or shrink based on time buckets
    const [bucketStartUtc, bucketEndUtc] = getTimeBucket(startUtc, endUtc)

    const timeRangeChanged = lastTimesStart !== bucketStartUtc || lastTimesEnd != bucketEndUtc

    if (timeRangeChanged) {
      // console.warn("shrunk times", new Date(startUtc), new Date(endUtc))
      times.value = data.value.map((d) => d.timeUtc).filter((t) => t >= bucketStartUtc && t <= bucketEndUtc)
      lastTimesStart = bucketStartUtc
      lastTimesEnd = bucketEndUtc
    }
  }

  function transitionValue(
    startVal: number,
    endVal: number,
    callback: (val: number, progress: number) => void,
    options: {
      immediate?: boolean
      onFinish?: () => void
    } = {}
  ) {
    const { immediate = false, onFinish = noop } = options

    const { start, pause, resume } = useTransitionFn(
      startVal,
      endVal,
      (val, progress) => {
        callback(val, progress)
      },
      {
        transition: TransitionPresets.easeInOutCubic as EasingFunction,
        // duration: 2000,
        onFinished: () => {
          onFinish()
        }
      }
    )
    if (immediate) {
      start()
    }
    return {
      start,
      pause,
      resume
    }
  }

  async function init(startUtc: number, endUtc: number) {
    // we have to assume datasource changed, so reset fetch times
    startTimeUtc.value = startUtc
    endTimeUtc.value = endUtc
    timeRange.value = endUtc - startUtc

    // we fetch with a start buffer of 2
    await updateData(startTimeUtc.value, endTimeUtc.value)

    endTimeUtc.value = data.value.at(-1)!.timeUtc
    timeRange.value = endTimeUtc.value - startTimeUtc.value
    // console.warn("data, times", data.value, timeCoordinates.value)

    atNow.value = true
  }

  async function move(by: number, skipTransition?: boolean) {
    if (isTransitioning) {
      ++updatesQueued
      if (updatesQueued > 2) return
      await waitFor(() => !isTransitioning)
      --updatesQueued
    }

    isTransitioning = !skipTransition

    // console.warn("times before", toRaw(timeCoordinates.value))
    await updateData(startTimeUtc.value + by, endTimeUtc.value + by)
    // console.warn("times after", toRaw(timeCoordinates.value))

    if (isPanning.value || translateTimeBy.value) return

    const maxTime = data.value.at(-1)!.timeUtc
    if (endTimeUtc.value + by >= maxTime) {
      atNow.value = true
      by = maxTime - endTimeUtc.value
      if (by == 0) return
    } else {
      atNow.value = false
    }

    // "snap to end" feature
    if (endTimeUtc.value + by > maxTime - 0.05 * timeRange.value) {
      by = maxTime - endTimeUtc.value
      atNow.value = true
    }

    const minTime = data.value[0].timeUtc

    if (startTimeUtc.value + by <= minTime) {
      atStart.value = true
      by = minTime - startTimeUtc.value
      if (by == 0) return
    } else {
      atStart.value = false
    }

    startTimeUtc.value += by
    endTimeUtc.value += by

    if (skipTransition) {
      shrinkTimeCoordinates(startTimeUtc.value, endTimeUtc.value)
      return
    }
    translateTimeBy.value = -by

    // we set transitionTime and transition it down to 0

    translateFactor.value = 0
    transitionValue(
      0,
      1,
      (val) => {
        translateFactor.value = val
      },
      {
        immediate: true,
        onFinish: () => {
          translateTimeBy.value = 0
          shrinkTimeCoordinates(startTimeUtc.value, endTimeUtc.value)
          isTransitioning = false
        }
      }
    )
  }
  async function moveToNow(skipTransition?: boolean) {
    const diffToLatest = getUtcNow() - endTimeUtc.value
    if (diffToLatest == 0) return
    await move(diffToLatest, skipTransition)
    atNow.value = true
  }

  async function updateMin(utcTime: number, skipTransition?: boolean) {
    if (isTransitioning) {
      ++updatesQueued
      if (updatesQueued > 2) return 0
      await waitFor(() => !isTransitioning)
      --updatesQueued
    }

    const zoomIn = utcTime > startTimeUtc.value

    isTransitioning = !skipTransition

    await updateData(utcTime, endTimeUtc.value)

    if (!zoomIn) {
      if (data.value[0].timeUtc >= utcTime) utcTime = times.value[0]
    }

    if (utcTime == startTimeUtc.value) {
      isTransitioning = false
      return 0
    }

    timeRange.value = endTimeUtc.value - utcTime

    if (skipTransition) {
      startTimeUtc.value = utcTime
      shrinkTimeCoordinates(utcTime, endTimeUtc.value)
      return utcTime
    }

    transitionValue(
      startTimeUtc.value,
      utcTime,
      (val) => {
        startTimeUtc.value = val
      },
      {
        immediate: true,
        onFinish: () => {
          shrinkTimeCoordinates(utcTime, endTimeUtc.value)
          timeRange.value = endTimeUtc.value - utcTime
          isTransitioning = false
        }
      }
    )

    return utcTime
  }
  async function updateMax(utcTime: number, skipTransition?: boolean) {
    // const reduceTime = utcTime < endTimeUtc.value
    timeRange.value = utcTime - startTimeUtc.value

    await updateData(startTimeUtc.value, utcTime)

    if (skipTransition) {
      endTimeUtc.value = utcTime
      shrinkTimeCoordinates(startTimeUtc.value, utcTime)
      return utcTime
    }

    transitionValue(
      endTimeUtc.value,
      utcTime,
      (val) => {
        endTimeUtc.value = val
      },
      {
        immediate: true,
        onFinish: () => shrinkTimeCoordinates(startTimeUtc.value, utcTime)
      }
    )

    return utcTime
  }

  const SNAP_FACTOR = 0.02

  function pan(by: number) {
    isPanning.value = true

    translateTimeBy.value = 10

    let endTime = endTimeUtc.value - translateTimeBy.value * translateFactor.value + by
    let startTime = startTimeUtc.value - translateTimeBy.value * translateFactor.value + by

    updateData(startTime, endTime)

    const maxTime = data.value.at(-1)!.timeUtc

    // "snap to end" feature
    if (by > 0 && endTime < maxTime && endTime > maxTime - SNAP_FACTOR * timeRange.value) {
      by = maxTime - endTime
      // console.warn("snap", by, new Date(maxTime), new Date(endTime))
      endTime += by
      startTime += by
    }

    let byFactor = by / -10

    // endTime = endTimeUtc.value - translateTimeBy.value * translateFactor.value + by

    if (endTime > maxTime) {
      atNow.value = true
      return
    }

    atNow.value = endTime > maxTime - SNAP_FACTOR * timeRange.value

    const minTime = data.value[0].timeUtc

    if (startTime <= minTime) {
      atStart.value = true
      return
    } else {
      atStart.value = false
    }

    shrinkTimeCoordinates(startTime, endTime)

    translateFactor.value += byFactor
  }

  function stopPan() {
    isPanning.value = false

    startTimeUtc.value -= translateTimeBy.value * translateFactor.value
    endTimeUtc.value -= translateTimeBy.value * translateFactor.value

    translateTimeBy.value = 0
    translateFactor.value = 0
  }

  return {
    atNow,
    atStart,
    isPanning,
    timeControl: {
      init,
      move,
      moveToNow,
      updateMin,
      updateMax,
      pan,
      stopPan
    } as TimelineControl,
    startTimeUtc,
    endTimeUtc,
    timeRange,
    translateTimeBy,
    translateFactor,
    times
  } as TimeControlReturn
}
