// inspired by Vue Use useTransition: https://github.com/vueuse/vueuse/blob/main/packages/core/useTransition/index.ts
// but lower level on function level, supports simple fn callback giving value, progress

import { isFunction, MaybeRef } from "@vueuse/core"
import { clamp, identity as linear, noop } from "@vueuse/core"
import { unref } from "vue"
import { useRafFn } from "./useRafFn"

/**
 * Easing function
 */
export type EasingFunction = (n: number) => number
/**
 * Cubic bezier points
 */
type CubicBezierPoints = [number, number, number, number]

/**
 * Transition options
 */
export interface TransitionOptions {
  /**
   * Disables the transition
   */
  disabled?: MaybeRef<boolean>

  /**
   * Transition duration in milliseconds
   */
  duration?: MaybeRef<number>

  /**
   * Callback to execute after transition finishes
   */
  onFinished?: () => void

  /**
   * Callback to execute after transition starts
   */
  onStarted?: () => void

  /**
   * Easing function or cubic bezier points for calculating transition values
   */
  transition?: EasingFunction | CubicBezierPoints
}

/**
 * Create an easing function from cubic bezier points.
 */
function createEasingFunction([p0, p1, p2, p3]: CubicBezierPoints): EasingFunction {
  const a = (a1: number, a2: number) => 1 - 3 * a2 + 3 * a1
  const b = (a1: number, a2: number) => 3 * a2 - 6 * a1
  const c = (a1: number) => 3 * a1

  const calcBezier = (t: number, a1: number, a2: number) => ((a(a1, a2) * t + b(a1, a2)) * t + c(a1)) * t

  const getSlope = (t: number, a1: number, a2: number) => 3 * a(a1, a2) * t * t + 2 * b(a1, a2) * t + c(a1)

  const getTforX = (x: number) => {
    let aGuessT = x

    for (let i = 0; i < 4; ++i) {
      const currentSlope = getSlope(aGuessT, p0, p2)
      if (currentSlope === 0) return aGuessT
      const currentX = calcBezier(aGuessT, p0, p2) - x
      aGuessT -= currentX / currentSlope
    }

    return aGuessT
  }

  return (x: number) => (p0 === p1 && p2 === p3 ? x : calcBezier(getTforX(x), p1, p3))
}

declare type CallbackFn = (val: number, progress: number, time: number) => void

export function useTransitionFn(
  sourceValue: number,
  destinationValue: number,
  fn: CallbackFn,
  options: TransitionOptions = {}
) {
  const { duration = 300, onFinished = noop, onStarted = noop, transition = linear } = options

  const transitionFn = isFunction(transition) ? transition : createEasingFunction(transition)

  let currentDuration: number
  let endAt: number
  let startAt: number
  let diff: number

  const { resume, pause } = useRafFn(
    (now: number) => {
      const progress = clamp(1 - (endAt - now) / currentDuration, 0, 1)

      const output = sourceValue + diff * transitionFn(progress)
      fn(output, progress, now)

      if (progress >= 1) {
        pause()
        onFinished()
      }
    },
    { immediate: false }
  )

  // start the animation loop when source vector changes
  const start = () => {
    pause()

    currentDuration = unref(duration)
    diff = destinationValue - sourceValue
    startAt = performance.now()
    endAt = startAt + currentDuration

    resume()
    onStarted()
  }

  return {
    start,
    resume,
    pause
  }
}
