import { ref, watch, Ref } from "vue"
import { TransitionPresets, useTransition } from "@vueuse/core"

type ScaleType = "linear" | "exponential"

export interface NumberScaleOptions {
  /** min == null means autoscaling */
  domainMin: number | null
  /** max == null means autoscaling */
  domainMax: number | null
  type: ScaleType
  /** defaults to 300ms */
  transitionTime?: number
}

export function useNumberScale(options: NumberScaleOptions, range: Ref<number>, supressTransitions: Ref<boolean>) {
  let minMax: Record<string, [number, number]> = {}
  let domainMin: Ref<number> = ref(0)
  let domainMax: Ref<number> = ref(0)

  const transitionedDomainMin = useTransition(domainMin, {
    duration: options.transitionTime || 200,
    disabled: supressTransitions,
    transition: TransitionPresets.easeInOutCubic
  })
  const transitionedDomainMax = useTransition(domainMax, {
    duration: options.transitionTime || 200,
    disabled: supressTransitions,
    transition: TransitionPresets.easeInOutCubic
  })

  let counter = 0

  function scaleValues(
    values: number[],
    type: ScaleType,
    domainMin: number,
    domainMax: number,
    range: number
  ): number[] {
    // scale based on all min/max when using autoscale
    if (type != "linear") {
      throw new Error(`scale type ${type} not supported`)
    }

    const domainSize = domainMax - domainMin
    const scaled = values.map((v) => (((v - domainMin) / domainSize) * range) | 0)

    // console.warn("min max scaled", domainMin, domainMax, range, scaled)

    return scaled
  }

  function scaleValue(value: number): number {
    // scale based on all min/max when using autoscale
    if (options.type != "linear") {
      throw new Error(`scale type ${options.type} not supported`)
    }

    const domainSize = domainMax.value - domainMin.value
    return (((value - domainMin.value) / domainSize) * range.value) | 0
  }

  function calculateDomainMinMax() {
    if (range.value == 0) return

    const allMinMax = Object.values(minMax) as [number, number][]

    if (options.domainMin == null) {
      // set domainMin ref based on observed min
      const dMin = Math.min(...allMinMax.map((m) => m[0]))
      if (dMin != domainMin.value) {
        // todo: scale with transition
        domainMin.value = dMin
      }
    } else {
      domainMin.value = options.domainMin
    }

    if (options.domainMax == null) {
      // set domainMin ref based on observed min
      const dMax = Math.max(...allMinMax.map((m) => m[1]))
      if (dMax != domainMax.value) {
        // todo: scale with transition
        // console.warn("old new max", allMinMax, domainMax.value, dMax)
        domainMax.value = dMax
      }
    } else {
      domainMax.value = options.domainMax
    }
  }

  watch(
    () => options,
    () => {
      calculateDomainMinMax()
    },
    { deep: true }
  )

  let lastRange = 0

  watch(
    () => range.value,
    (range) => {
      if (lastRange == 0) calculateDomainMinMax()

      lastRange = range
    }
  )

  /** scale data based on visible data available */
  function scaleData(values: Ref<number[]>, visibleValues: Ref<number[]>): Ref<number[]> {
    let scaledValues: Ref<number[]> = ref([])
    let key = "" + ++counter
    let lastMin = 0,
      lastMax = 0

    watch(
      () => [values.value, visibleValues.value],
      ([vals, visibleVals]) => {
        if (!vals.length || !visibleVals.length) return

        const newMinMax = [Math.min(...visibleVals), Math.max(...visibleVals)] as [number, number]
        minMax[key] = newMinMax

        calculateDomainMinMax()

        if (range.value == 0) return

        scaledValues.value = scaleValues(
          values.value,
          options.type,
          transitionedDomainMin.value,
          transitionedDomainMax.value,
          range.value
        )

        // console.warn("vals update", range.value, vals, visibleValues, scaledValues.value)

        lastMin = domainMin.value
        lastMax = domainMax.value
      },
      { immediate: true }
    )

    watch(
      () => range.value,
      (range) => {
        scaledValues.value = scaleValues(
          values.value,
          options.type,
          transitionedDomainMin.value,
          transitionedDomainMax.value,
          range
        )
      }
    )

    watch(
      () => [transitionedDomainMin.value, transitionedDomainMax.value],
      () => {
        if (transitionedDomainMin.value == lastMin && transitionedDomainMax.value == lastMax) {
          // console.warn("ignore domain change", key)
          return
        }

        scaledValues.value = scaleValues(
          values.value,
          options.type,
          transitionedDomainMin.value,
          transitionedDomainMax.value,
          range.value
        )

        // console.warn(
        //   "domain update",
        //   key,
        //   transitionedDomainMin.value,
        //   transitionedDomainMax.value,
        //   range.value,
        //   values.value,
        //   scaledValues.value
        // )

        lastMin = domainMin.value
        lastMax = domainMax.value
      }
    )

    return scaledValues
  }

  return {
    scaleData,
    scaleValue
  }
}
