import { deepClone } from "../../helpers"
export type ResizeType = "nw" | "ne" | "se" | "sw" | "nn" | "ee" | "ss" | "ww"

export class BoxFitDetector {
  readonly width: number
  readonly height: number
  freeSpaceBitmask: number

  constructor(
    width: number,
    height: number,
    tilesOrBitmask: { x: number; y: number; width: number; height: number }[] | number
  ) {
    this.width = width
    this.height = height
    if (width * height > 32) {
      throw new Error("not implemented: width/height dimensions bigger than 32 bit, change to BigInt")
    }
    if (typeof tilesOrBitmask == "number") {
      this.freeSpaceBitmask = tilesOrBitmask
    } else {
      this.freeSpaceBitmask = this.#createFreeSpaceBitmask(tilesOrBitmask)
    }
  }

  /** takes tile position and returns used space space bitmask (1s are cells occupied by tile) */
  #convertPos2Bitmap(pos: { x: number; y: number; width: number; height: number }): number {
    let bitmask = 0
    // for each tile position, set bits to 0, we assume lowest bit (right most pos, little endian notation) are
    // at start of slice, so x=1,y=1 => bit 0, x=4,y=8 => bit=31
    let rowBitMask = (Math.pow(2, pos.width) - 1) << (pos.x - 1)

    for (let row = pos.y; row < pos.y + pos.height; row++) {
      // for each row, generate bitmask and add to final bitmask
      bitmask |= rowBitMask << ((row - 1) * this.width)
    }

    return bitmask
  }

  /** takes tiles and returns free space bitmask (1s are cells not occupied) */
  #createFreeSpaceBitmask(tiles: { x: number; y: number; width: number; height: number }[]): number {
    // "free space" bitmask (bit set means free space not occupied)
    let bitmask = 0xffffffff
    // for each tile position, set bits to 0, we assume lowest bit (right most pos, little endian notation) are
    // at start of slice, so x=1,y=1 => bit 0, x=4,y=8 => bit=31
    tiles.forEach((tile) => {
      // we substract the tile bitmask (1s for area of tile) from "free space" bitmask (1 is free space not occupied)
      bitmask &= ~this.#convertPos2Bitmap(tile)
    })

    return bitmask
  }

  clone(): BoxFitDetector {
    return new BoxFitDetector(this.width, this.height, this.freeSpaceBitmask)
  }

  isFreeSpace(pos: { x: number; y: number; width: number; height: number }): boolean {
    if (pos.x == 0 || pos.y == 0) return false
    if (pos.x + pos.width - 1 > this.width || pos.y + pos.height - 1 > this.height) return false

    const posBitmap = this.#convertPos2Bitmap(pos)
    return (this.freeSpaceBitmask & posBitmap) === posBitmap
  }

  getPossiblePositions(
    tileSize: { width: number; height: number },
    excludedPositions?: { x: number; y: number; width: number; height: number }[]
  ) {
    const positions: any[] = []

    const coveredMask = excludedPositions ? this.#createFreeSpaceBitmask(excludedPositions) : 0xffffffff

    for (let y = 1; y <= this.height - (tileSize.height - 1); y++) {
      for (let x = 1; x <= this.width - (tileSize.width - 1); x++) {
        const pos = { x, y, width: tileSize.width, height: tileSize.height }
        const posBitmap = this.#convertPos2Bitmap(pos)
        // has to be in an available space and not completely covered by previously found positions
        if (this.isFreeSpace(pos) && (coveredMask & posBitmap) !== 0) {
          positions.push(pos)
        }
      }
    }

    return positions
  }

  excludePositions(positions: { x: number; y: number; width: number; height: number }[]) {
    positions.forEach((tile) => {
      // we substract the tile bitmask (1s for area of tile) from "free space" bitmask (1 is free space not occupied)
      this.freeSpaceBitmask &= ~this.#convertPos2Bitmap(tile)
    })
  }
}

export type CellAnchor = { cellX: number; cellY: number }
export type CellAnchorSkew = { xSkew: boolean; ySkew: boolean }

/** Given a box position, compute the middle anchor point */
export function getBoxMoveAnchor(box: { x: number; y: number; width: number; height: number }) {
  // if we have even height/widht, the center anchor position is "between" cells compared to resize,
  // so we set xSkew/ySkew to carry information about "half cell anchor skew"
  return {
    cellX: box.x + Math.floor((box.width - 1) / 2),
    cellY: box.y + Math.floor((box.height - 1) / 2)
  } as CellAnchor
}

export function getBoxMoveCursorSkew(width: number, height: number) {
  const isWidthEven = !(width & 1)
  const isHeightEven = !(height & 1)

  // if we have even height/widht, the center anchor position is "between" cells compared to resize,
  // so we set xSkew/ySkew to carry information about "half cell anchor skew"
  return {
    xSkew: isWidthEven,
    ySkew: isHeightEven
  } as CellAnchorSkew
}

export function computeAnchorMousePos(
  anchor: CellAnchor,
  anchorSkew: CellAnchorSkew,
  sliceCoords: { x: number; y: number },
  cellInfo: { size: number; gap: number }
) {
  // console.warn(oldAnchor)
  const cellAndGap = cellInfo.size + cellInfo.gap
  return {
    x: sliceCoords.x + (anchor.cellX - 1) * cellAndGap + (anchorSkew.xSkew ? cellAndGap : cellAndGap / 2),
    y: sliceCoords.y + (anchor.cellY - 1) * cellAndGap + (anchorSkew.ySkew ? cellAndGap : cellAndGap / 2)
  }
}

export function getAnchorBoxFromResizeBox(
  box: { x: number; y: number; width: number; height: number },
  resizeType: ResizeType,
  invert?: boolean
) {
  let resize = resizeType
  if (invert) {
    switch (resizeType) {
      case "se":
        resize = "nw"
        break
      case "sw":
        resize = "ne"
        break
      case "ne":
        resize = "sw"
        break
      case "nw":
        resize = "se"
        break
      case "nn":
        resize = "ss"
        break
      case "ss":
        resize = "nn"
        break
      case "ee":
        resize = "ww"
        break
      case "ww":
        resize = "ee"
        break
    }
  }
  const maxX = box.x + box.width - 1,
    maxY = box.y + box.height - 1
  switch (resize) {
    case "se":
      return { x: maxX, y: maxY, width: 1, height: 1 }
    case "sw":
      return { x: box.x, y: maxY, width: 1, height: 1 }
    case "nw":
      return { x: box.x, y: box.y, width: 1, height: 1 }
    case "ne":
      return { x: maxX, y: box.y, width: 1, height: 1 }
    case "ss":
      return { x: box.x, y: maxY, width: box.width, height: 1 }
    case "ww":
      return { x: box.x, y: box.y, width: 1, height: box.height }
    case "nn":
      return { x: box.x, y: box.y, width: box.width, height: 1 }
    case "ee":
      return { x: maxX, y: box.y, width: 1, height: box.height }
    default:
      throw new Error(`activeResizeType ${resizeType} not implemented`)
  }
}

/**
 * Given an anchor point and a number of tiles for blockage, assuming
 * starting the resize form a corner: determine all resize preview boxes for an
 *  initial anchor position to expand out from
 */
export function generateResizePreviews(
  tile: { x: number; y: number; width: number; height: number },
  resizeType: ResizeType,
  /** the mouse cursor cell position that should be used to pick preferred box position */
  sliceTiles: { x: number; y: number; width: number; height: number }[]
) {
  const blockingTiles = sliceTiles.filter((t) => !(t.x == tile.x && t.y == tile.y))

  if (resizeType == "se" || resizeType == "sw" || resizeType == "ne" || resizeType == "nw") {
    return generateCornerResizePreviews(tile, resizeType, blockingTiles)
  } else {
    return generateLinearResizePreviews(tile, resizeType, blockingTiles)
  }
}

/**
 * Given an anchor point and a number of tiles for blockage, assuming
 * starting the resize form a corner: determine all resize preview boxes for an
 *  initial anchor position to expand out from
 */
function generateCornerResizePreviews(
  tile: { x: number; y: number; width: number; height: number },
  resizeType: Extract<ResizeType, "se" | "sw" | "ne" | "nw">,
  /** the mouse cursor cell position that should be used to pick preferred box position */
  blockingTiles: { x: number; y: number; width: number; height: number }[]
) {
  const anchor = getAnchorBoxFromResizeBox(tile, resizeType, true)
  const map: Record<string, { x: number; y: number; width: number; height: number }> = {}
  const obstructed: [number, number][] = []

  let spaceMap = new BoxFitDetector(4, 8, blockingTiles)
  const box = { x: 0, y: 0, width: 0, height: 0 }

  for (let x = 1; x <= 4; x++) {
    for (let y = 1; y <= 8; y++) {
      // construct box from anchorPoint to x/y
      box.x = Math.min(anchor.x, x)
      box.width = 1 + Math.abs(anchor.x - x)
      box.y = Math.min(anchor.y, y)
      box.height = 1 + Math.abs(anchor.y - y)

      // check whether box is not obstructed
      const isFree = spaceMap.isFreeSpace(box)
      // if free, add to map
      if (isFree) map[`${x},${y}`] = deepClone(box)
      else obstructed.push([x, y])
    }
  }

  // now pathtrace each obstructed cell back to anchor and pick first free box found
  obstructed.forEach((pos) => {
    const [posX, posY] = pos
    const testablePositions: { x: number; y: number; width: number; height: number }[] = []

    const xStart = Math.min(anchor.x, posX)
    const xEnd = Math.max(anchor.x, posX)
    const yStart = Math.min(anchor.y, posY)
    const yEnd = Math.max(anchor.y, posY)

    // we span all coordinates up to anchor point
    for (let x = xStart; ; x = xEnd > xStart ? x + 1 : x - 1) {
      for (let y = yStart; ; y = yEnd > yStart ? y + 1 : y - 1) {
        testablePositions.push({
          x: Math.min(x, anchor.x),
          y: Math.min(y, anchor.y),
          width: 1 + Math.abs(anchor.x - x),
          height: 1 + Math.abs(anchor.y - y)
        })
        if (y == yEnd) break
      }
      if (x == xEnd) break
    }

    // sort with biggest area first
    testablePositions.sort((a, b) => (a.width * a.height > b.width * b.height ? -1 : 1))

    // console.warn("pos", pos, testablePositions)

    for (const testPos of testablePositions) {
      if (spaceMap.isFreeSpace(testPos)) {
        // console.warn("free space", testPos)
        map[`${posX},${posY}`] = testPos
        return
      }
    }
  })
  return map
}

/**
 * Given an anchor point and a number of tiles for blockage, assuming
 * we drag the resize in a certain direction: determine all resize preview boxes for a
 * purely horizontal/vertical resize (constrained to either width or height of box)
 */
function generateLinearResizePreviews(
  tile: { x: number; y: number; width: number; height: number },
  resizeType: Extract<ResizeType, "ss" | "nn" | "ww" | "ee">,
  /** the mouse cursor cell position that should be used to pick preferred box position */
  blockingTiles: { x: number; y: number; width: number; height: number }[]
) {
  const map: Record<string, { x: number; y: number; width: number; height: number }> = {}

  let spaceMap = new BoxFitDetector(4, 8, blockingTiles)

  if (resizeType == "ww" || resizeType == "ee") {
    let resizeFits: boolean[] = []
    const anchorX = resizeType == "ee" ? tile.x : tile.x + tile.width - 1

    const getHResizeBox = (x: number) => {
      return {
        x: Math.min(anchorX, x),
        y: tile.y,
        width: Math.max(anchorX, x) - Math.min(anchorX, x) + 1,
        height: tile.height
      }
    }

    // we treat both sides the same (you can run through tile like with corner resize)
    for (let x = 1; x <= 4; x++) {
      // check each time for x if box fits, save in array
      const allowed = (resizeType == "ww" && x <= anchorX) || (resizeType == "ee" && x >= anchorX)
      resizeFits.push(allowed && spaceMap.isFreeSpace(getHResizeBox(x)))
    }

    for (let x = 1; x <= 4; x++) {
      const pos = getHResizeBox(x)

      if (resizeFits[x - 1]) {
        for (let y = 1; y <= 8; y++) {
          map[`${x},${y}`] = pos
        }
        // add for all y same box
      } else {
        let nextFitX = x

        while (!resizeFits[nextFitX - 1]) {
          if (x < tile.x) {
            nextFitX++
          } else {
            nextFitX--
          }
        }
        for (let y = 1; y <= 8; y++) {
          map[`${x},${y}`] = map[`${nextFitX},${y}`]
        }
      }
    }
  } else {
    // for north/south instead
    let resizeFits: boolean[] = []
    const anchorY = resizeType == "ss" ? tile.y : tile.y + tile.height - 1

    const getVResizeBox = (y: number) => {
      return {
        x: tile.x,
        y: Math.min(anchorY, y),
        width: tile.width,
        height: Math.max(anchorY, y) - Math.min(anchorY, y) + 1
      }
    }

    for (let y = 1; y <= 8; y++) {
      const allowed = (resizeType == "ss" && y >= anchorY) || (resizeType == "nn" && y <= anchorY)
      resizeFits.push(allowed && spaceMap.isFreeSpace(getVResizeBox(y)))
    }

    for (let y = 1; y <= 8; y++) {
      const pos = getVResizeBox(y)

      if (resizeFits[y - 1]) {
        for (let x = 1; x <= 4; x++) {
          map[`${x},${y}`] = pos
        }
        // add for all y same box
      } else {
        let nextFitY = y

        while (!resizeFits[nextFitY - 1] && nextFitY >= 1 && nextFitY <= 8) {
          if (y < tile.y) {
            nextFitY++
          } else {
            nextFitY--
          }
        }
        for (let x = 1; x <= 4; x++) {
          map[`${x},${y}`] = map[`${x},${nextFitY}`]
        }
      }
    }
  }
  return map
  // the other directions basically the same
}

/**
 * Given an width and height and a number of tiles for blockage,
 * determine all move preview boxes for this size or below
 */
export function generateMovePreviews(
  tile: { x: number; y: number; width: number; height: number },
  /** the mouse cursor cell position that should be used to pick preferred box position */
  otherTiles: { x: number; y: number; width: number; height: number }[]
) {
  const map: Record<string, { x: number; y: number; width: number; height: number }> = {}

  let width = tile.width,
    height = tile.height

  let freeSpaceDetector = new BoxFitDetector(4, 8, otherTiles)
  let tilePositions: {
    x: number
    y: number
    width: number
    height: number
  }[] = []

  const aspectRatio = width / height
  const minAspectRatio = Math.min(1, aspectRatio)

  do {
    if (width / height < minAspectRatio) {
      if (Math.abs((width - 1) / height - aspectRatio) < Math.abs(width / (height - 1) - aspectRatio)) {
        width--
      } else {
        height--
      }
      continue
    }

    // exclude found bigger positions from next search for smaller positions
    const newPositions = freeSpaceDetector.getPossiblePositions({ width, height }, tilePositions).map((pos) => {
      return {
        x: pos.x,
        y: pos.y,
        width: width,
        height: height
      }
    })
    // console.warn(width, height, newPositions)
    tilePositions = tilePositions.concat(newPositions)

    if ((width - 1) * height > width * (height - 1)) {
      width--
    } else {
      height--
    }
  } while (width >= 1 && height >= 1)

  if (!tilePositions.length) return map

  // now just go through each cell and see if tile fits or if we need to find closest tile
  for (let x = 1; x <= 4; x++) {
    for (let y = 1; y <= 8; y++) {
      // search for tile with closest center to x/y coordinate
      tilePositions.sort((a, b) => {
        const anchorA = getBoxMoveAnchor(a),
          anchorB = getBoxMoveAnchor(b)
        const skewA = getBoxMoveCursorSkew(a.width, a.height),
          skewB = getBoxMoveCursorSkew(b.width, b.height)
        return Math.sqrt(
          (anchorA.cellX + (skewA.xSkew ? 1 : 0) - x) ** 2 + (anchorA.cellY + (skewA.ySkew ? 1 : 0) - y) ** 2
        ) <
          Math.sqrt((anchorB.cellX + (skewB.xSkew ? 1 : 0) - x) ** 2 + (anchorB.cellY + (skewB.ySkew ? 1 : 0) - y) ** 2)
          ? -1
          : 1
      })
      map[`${x},${y}`] = tilePositions[0]
    }
  }

  return map
}

export function fitNewTileOnCursor(
  tileSize: { width: number; height: number },
  /** the mouse cursor cell position that should be used to pick preferred box position */
  cursorCellPos: { x: number; y: number },
  boxFitDetector: BoxFitDetector
): { x: number; y: number; width: number; height: number } | null {
  // order by distance, return shortest to center of tile fit
  const cp = cursorCellPos

  // now search for incrementally smaller box sizes
  let width = tileSize.width,
    height = tileSize.height

  while (width >= 1 && height >= 1) {
    let freePositions = boxFitDetector.getPossiblePositions({ width: width, height: height })

    freePositions = freePositions.filter(
      (pos) => pos.x <= cp.x && pos.x + (width - 1) >= cp.x && pos.y <= cp.y && pos.y + (height - 1) >= cp.y
    )

    freePositions.sort((a, b) =>
      Math.sqrt((a.x - cp.x) ** 2 + (a.y - cp.y) ** 2) < Math.sqrt((b.x - cp.x) ** 2 + (b.y - cp.y) ** 2) ? -1 : 1
    )
    if (freePositions.length > 0) {
      const pos = freePositions[0]
      return { x: pos.x, y: pos.y, width: width, height: height }
    }

    if ((width - 1) * height > width * (height - 1)) {
      width--
    } else {
      height--
    }
  }

  return null
}

const CELL_SWITCH_AT = 0.7
const CELL_SWITCH_AT_DIAGONAL = Math.sqrt(CELL_SWITCH_AT ** 2 * 2)

/** given pixel diffs x/y from origin drag point, return width/height changes */
export function computeAnchorDiff(
  anchor: CellAnchor,
  anchorSkew: CellAnchorSkew,
  anchorMousePos: { x: number; y: number },
  mousePos: { x: number; y: number },
  cellDimensions: { size: number; gap: number }
): { xDiff: number; yDiff: number } {
  // compute old anchor position
  const xDiff = mousePos.x - anchorMousePos.x
  const yDiff = mousePos.y - anchorMousePos.y

  const baseUnit = cellDimensions.size // + cellGap
  const baseXCells = Math.trunc(xDiff / baseUnit)
  const baseYCells = Math.trunc(yDiff / baseUnit)
  const diffXRemainder = xDiff - baseXCells * baseUnit
  const diffYRemainder = yDiff - baseYCells * baseUnit

  const diagonalDiff = Math.sqrt(diffXRemainder ** 2 + diffYRemainder ** 2)
  const xCellSize = Math.abs(diffXRemainder / baseUnit)
  const yCellSize = Math.abs(diffYRemainder / baseUnit)
  const dCellSize = Math.abs(diagonalDiff / baseUnit)
  let cellDiffX = baseXCells,
    cellDiffY = baseYCells

  // console.warn("diffs", xDiff, yDiff)

  const angle = Math.abs(90 - (Math.abs(Math.atan2(xDiff, yDiff)) * 180) / Math.PI)
  if (dCellSize > CELL_SWITCH_AT_DIAGONAL && angle >= 25 && angle <= 65) {
    cellDiffX += xDiff < 0 ? -1 : 1
    cellDiffY += yDiff < 0 ? -1 : 1
  } else if (Math.max(xCellSize, yCellSize) > CELL_SWITCH_AT) {
    if (angle < 25) {
      cellDiffX += xDiff < 0 ? -1 : 1
    } else if (angle > 65) {
      cellDiffY += yDiff < 0 ? -1 : 1
    }
  }

  let newX = anchor.cellX + cellDiffX
  let newY = anchor.cellY + cellDiffY

  // restrict anchor
  const xMax = anchorSkew.xSkew ? 3 : 4
  if (newX <= 0 || newX > xMax) {
    cellDiffX = 0
    cellDiffY = 0
  }
  const yMax = anchorSkew.ySkew ? 7 : 8
  if (newY <= 0 || newY > yMax) {
    cellDiffX = 0
    cellDiffY = 0
  }

  return {
    xDiff: cellDiffX,
    yDiff: cellDiffY
  }
}

export function getCellPos(
  mouse: { clientX: number; clientY: number },
  slice: { x: number; y: number },
  cellSize: number,
  cellGap: number
) {
  const xPos = Math.max(1, Math.min(4, Math.ceil((mouse.clientX - slice.x) / (cellSize + cellGap))))
  const yPos = Math.max(1, Math.min(8, Math.ceil((mouse.clientY - slice.y) / (cellSize + cellGap))))
  return { x: xPos, y: yPos }
}

export function getCellCenterPoint(
  anchor: CellAnchor,
  sliceCoords: { x: number; y: number },
  cellSize: number,
  cellGap: number
) {
  return {
    x: sliceCoords.x + (anchor.cellX - 1) * (cellSize + cellGap) + cellSize / 2,
    y: sliceCoords.y + (anchor.cellY - 1) * (cellSize + cellGap) + cellSize / 2
  }
}

export function getBoxDomRect(
  box: { x: number; y: number; width: number; height: number },
  sliceCoords: { x: number; y: number },
  cellSize: number,
  cellGap: number
) {
  const padding = 4
  const left = sliceCoords.x + padding + (box.x - 1) * (cellSize + cellGap) + cellGap
  const top = sliceCoords.y + padding + (box.y - 1) * (cellSize + cellGap) + cellGap

  return {
    left: Math.round(left),
    top: Math.round(top),
    right: Math.round(left + (cellSize + cellGap) - padding),
    bottom: Math.round(top + (cellSize + cellGap) - padding),
    width: Math.round(box.width * (cellSize + cellGap) - cellGap),
    height: Math.round(box.height * (cellSize + cellGap) - cellGap)
  }
}

export function queryCellSizeAndGap(elem: HTMLElement) {
  const firstCell = elem.querySelector(".cell")
  const firstRect = firstCell?.getBoundingClientRect()!
  const secondCell = elem.querySelector(".cell:nth-child(2)")
  const secondRect = secondCell?.getBoundingClientRect()!

  if (!firstRect || !secondRect) {
    throw new Error("cell size computation failed for elem " + elem.dataset.id)
  }

  return {
    gap: Math.round(secondRect.x - (firstRect.x + firstRect.width)),
    cellSize: firstRect.width
  }
}
