import {
  line as d3Line,
  curveBumpX,
  curveLinear,
  curveMonotoneX,
  curveStep,
  curveStepAfter,
  curveStepBefore
} from "d3-shape"
import { convertUtc2Local, getUtcNow } from "../../../../helpers"

import { CurveType } from "../../../../models/tile-models"

export function createLineCurve(data: [number, number][], curveType?: CurveType): string {
  let curve: any

  switch (curveType) {
    case "bumpX":
      curve = curveBumpX
      break
    case "linear":
      curve = curveLinear
      break
    case "monotoneX":
      curve = curveMonotoneX
      break
    case "step":
      curve = curveStep
      break
    case "stepAfter":
      curve = curveStepAfter
      break
    case "stepBefore":
      curve = curveStepBefore
      break
    default:
      curve = curveMonotoneX
    // throw Error('curve not known')
  }

  var lineD3 = d3Line().curve(curve)
  return lineD3(data)
}

const tickRangesInSeconds = [
  10,
  20,
  30,
  60,
  120,
  300,
  600,
  1800,
  3600,
  2 * 3600,
  4 * 3600,
  12 * 3600,
  24 * 3600,
  2 * 24 * 3600,
  4 * 24 * 3600,
  7 * 24 * 3600
]

export function chooseTimeTickRange(timeRange: number) {
  const rangeInSecs = timeRange / 1000

  for (const range of tickRangesInSeconds) {
    if (rangeInSecs / range <= 4) {
      return range
    }
  }

  return tickRangesInSeconds[tickRangesInSeconds.length - 1]
}

const todayMidnightLocal = +new Date(new Date().setHours(0, 0, 0, 0))

export function getTickStartTime(utcStartTime: number, tickRangeInSeconds: number) {
  const subtractMin = (convertUtc2Local(utcStartTime) - todayMidnightLocal) % (tickRangeInSeconds * 1000)
  return utcStartTime - subtractMin
}

export function getTickEndTime(utcEndTime: number, tickRangeInSeconds: number) {
  const addToMax =
    tickRangeInSeconds * 1000 - ((convertUtc2Local(utcEndTime) - todayMidnightLocal) % (tickRangeInSeconds * 1000))
  return utcEndTime + addToMax
}

export const dayTime = 24 * 3600 * 1000

export function formatTime(
  utcTime: number,
  format: Intl.DateTimeFormat,
  dayFormat: Intl.DateTimeFormat,
  relativeFormat: Intl.RelativeTimeFormat
) {
  const todayString = dayFormat.format(new Date())

  const timeString = format.format(convertUtc2Local(utcTime)).replace(",", "")
  if (timeString.startsWith(todayString) && timeString.length > todayString.length) {
    const todayReplacement = timeString.length > todayString.length ? "" : relativeFormat.format(0, "day")
    return timeString.replace(todayString, todayReplacement)
  }
  return timeString
}

function getLabelPxSize(timeUtc: number, dtFormatFn: (time: number) => string) {
  const label = dtFormatFn(timeUtc)
  const labelCharacterWidth = label.length - (label.match(/[\. ]/g) || []).length * 0.5
  // we assume 7.5px per digit char, TODO find a more accurate way
  const labelSizeInPx = 7.5 * labelCharacterWidth
  return labelSizeInPx
}

/** choose ticks based on time
 * @returns array of utc times to render tick markers for
 */
export function getTimeTicks(
  chartWidth: number,
  startTimeUtc: number,
  endTimeUtc: number,
  timeRange: number,
  dtFormatFn: (time: number) => string
) {
  let timePerTick = chooseTimeTickRange(timeRange) * 1000

  const rangeUnderAWeek = timePerTick <= 4 * dayTime

  const tickTimes: number[] = []

  if (rangeUnderAWeek) {
    // console.warn("under  a week")
    const labelSizeInPx = getLabelPxSize(startTimeUtc, dtFormatFn)

    let pxPerTick = (timePerTick / timeRange) * chartWidth
    if (labelSizeInPx > 0.9 * pxPerTick) {
      const rangeIndex = tickRangesInSeconds.indexOf(timePerTick / 1000)
      let newTimePerTick = tickRangesInSeconds[rangeIndex + 1] * 1000
      pxPerTick = (newTimePerTick / timeRange) * chartWidth
      if (newTimePerTick && labelSizeInPx > 0.9 * pxPerTick) {
        newTimePerTick = tickRangesInSeconds[rangeIndex + 2] * 1000
      }
      if (newTimePerTick) {
        timePerTick = newTimePerTick
        startTimeUtc = getTickStartTime(startTimeUtc, timePerTick / 1000)
        endTimeUtc = getTickEndTime(endTimeUtc, timePerTick / 1000)
      }
    }

    let tickTime = startTimeUtc

    while (tickTime <= endTimeUtc) {
      tickTimes.push(tickTime)
      tickTime += timePerTick
    }

    return tickTimes
  }

  // if timePerTick is >= 4 days: we want to be more month-specific and not adhere to exact constant range anymore

  const startDate = new Date(convertUtc2Local(startTimeUtc))

  let day = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  let tickTime = +day

  const dayDiff = timeRange / dayTime
  const utcNow = getUtcNow()

  while (tickTime <= endTimeUtc && tickTime <= utcNow) {
    tickTimes.push(tickTime)

    if (dayDiff < 32) {
      tickTimes.push(tickTime + 7 * dayTime)
    }
    if (dayDiff < 60) {
      tickTimes.push(tickTime + 14 * dayTime)
    }
    if (dayDiff < 32) {
      tickTimes.push(tickTime + 21 * dayTime)
    }
    const nextMonth = new Date(+day + 32 * dayTime)
    day = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1)
    tickTime = +day
  }

  if (utcNow - tickTimes.at(-1)! > 3 * dayTime) {
    tickTimes.push(todayMidnightLocal)
  }

  return tickTimes
}
