export type ProviderTypes = "google" | "github" | "apple"

export interface UserInfo {
  id: string
  name: string
  imageUrl: string
  email: string
  loginProvider: ProviderTypes | null
  createdUtc: number
  lastModifiedUtc: number
}

let userInfo: UserInfo | null

export function getUserInfo(): UserInfo | null {
  return userInfo
}

export async function logoutUser() {
  await fetch("/api/auth/logout", {
    method: "POST"
  })
  userInfo = null
}

export async function fetchUserInfo(): Promise<UserInfo | null> {
  const response = await fetch("/api/auth/user")
  if (!response.ok) {
    return null
  } else {
    userInfo = (await response.json()) as UserInfo
  }
  return userInfo
}
