import { App } from "@vue/runtime-core"

export function registerResizeDirective(app: App<Element>) {
  let observer: ResizeObserver
  let listenerMap: WeakMap<Element, (rect: DOMRectReadOnly) => void> = new WeakMap()

  // will be notifying at most 10 times per second
  const notify = (entries: ResizeObserverEntry[]) => {
    for (const entry of entries) {
      let func = listenerMap.get(entry.target)
      if (!func) {
        console.warn("resizeDirective: element callback not found", entry.target)
        continue
      }
      func(entry.contentRect)
    }
  }

  app.directive("resize", {
    beforeMount(el: Element, binding) {
      if (!observer) {
        observer = new ResizeObserver(notify)
      }
      listenerMap.set(el, binding.value)
      observer.observe(el)
    },

    beforeUnmount(el: Element) {
      try {
        observer.unobserve(el)
        listenerMap.delete(el)
      } catch (e) {
        console.error("resizeDirective: element callback delete failed", e)
      }
    }
  })
}
